import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Wasender from "./Wasender";
import sendEmail from ".././SendEmail";


function Endpoint() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [isCallBackVisible, setIsCallBackVisible] = useState();
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const paymentStatusPage = params.get("status");
  const paymentRefPage = params.get("transref");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";
  const [userSolde, setUserSolde] = useState("");
  // c

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }
  // Check if user logged
  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }
  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }
  useEffect(() => {
    checkUserExist();
  }, []);

  function DisplayStatus() {
    try {
      if (paymentStatusPage && paymentStatusPage === "SUCCESS") {
        axios
          .get(process.env.React_App_base_url + `users/` + userID, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(async (res) => {
            const userData = res.data.data;
            const solde=userData.money;
              setUserSolde(userData.money);
            //  console.log(res);

            if (res) {
              var payID = localStorage.getItem("odr");
              var UpPrice = localStorage.getItem("money");
              console.log(UpPrice, solde);
              var newSolde= Number(UpPrice) + Number(solde);
              console.log(newSolde);
              var Vendor1="+22995898072";
              var  objet="Une nouvelle abonnement sur WABAKIT";
              var Email1="lynoshk@yahoo.fr";
              // var Email2 = "helousgeorg77@gmail.com";
              // var Credisms = atob(localStorage.getItem("userCredit"));
               let VisaPayment =
                 "Customer WabaKit par all visa mode." +
                 " " +
                 "Code de la transaction est :" +
                 " " +
                 paymentRefPage + " | Réf: "+ payID+
                 ". " +
                 " Veuillez faire le checking pour confirmer le paiement et mettre à jour le compte du client. Merci ";
//Confirmation du rechargement de compte
              var confirmData = JSON.stringify({
                paymentStatus: "confirmed",
                montant: newSolde,
              });
              axios
                .patch(
                  process.env.React_App_base_url + `payments/` + payID,
                  confirmData,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  setIsCallBackVisible(true);
                });
//Mise à jour du compte de l'utilisateur
              var UpdateCompte = JSON.stringify({
                money: newSolde,
                notify:"01",
              });
              axios
                .patch(
                  process.env.React_App_base_url + `users/` + userID,
                  UpdateCompte,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  setIsCallBackVisible(true);
                });



              // Wasender(Vendor1,VisaPayment);
            sendEmail(Email1, objet, VisaPayment);       

            }
          });
        setIsCallBackVisible(true);
      }
    } catch (error) {
      // console.log("Error", error);
      navigate("/");
    }
  }

  useEffect(() => {
    if (document.write) {
      DisplayStatus();
    }
  }, []);

  return (
    <>
      <div className="bg-blue-800 -mt-16 text-gray-100 mb-12">
        <body>
          <div class="flex items-center justify-center h-screen -mb-10">
            <div>
              {isCallBackVisible ? (
                <div class="flex flex-col items-center -mt-24  space-y-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-green-600 w-28 h-28"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <h1 class="text-4xl font-bold mx-auto text-center">
                    Merci pour la confirmation de votre paiement !
                  </h1>
                  <p className="mx-auto px-24 text-center">
                    Votre compte sera mise à jour avec le nouveau solde.
                  </p>
                  <p className="text-center">
                    Nous vous souhaitons une excellente journée 👋 !
                  </p>
                  <a
                    href="../home/dashboard"
                    class="inline-flex items-center px-4 py-2 text-white bg-pink-500 border border-pink-500 rounded rounded-full hover:bg-gray-100 hover:text-pink-500 focus:outline-none focus:ring"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-3 h-3 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span class="text-sm font-medium">Tableau de bord</span>
                  </a>
                </div>
              ) : (
                <div class="flex flex-col items-center -mt-24  -mb-32 space-y-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-28 h-28 mr-2"
                    viewBox="0 0 2048 2048"
                  >
                    <path
                      fill="red"
                      d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37zm0 1920q124 0 238-32t214-90t181-140t140-181t91-214t32-239q0-124-32-238t-90-214t-140-181t-181-140t-214-91t-239-32q-124 0-238 32t-214 90t-181 140t-140 181t-91 214t-32 239q0 124 32 238t90 214t140 181t181 140t214 91t239 32zm443-1249l-352 353l352 353l-90 90l-353-352l-353 352l-90-90l352-353l-352-353l90-90l353 352l353-352l90 90z"
                    />
                  </svg>
                  <h1 class="text-4xl font-bold text-center">
                    Votre paiement a échoué !
                  </h1>
                  <p className="text-center">
                    Veuillez vérifier le solde de votre compte Mobile ou les
                    informations de votre carte VISA et réessayer !
                  </p>
                  <p className="text-center">
                    Nous vous souhaitons une excellente journée 👋 !
                  </p>
                  <a
                    href="../home/dashboard"
                    class="inline-flex items-center px-4 py-2 text-white bg-blue-500 border border-blue-500 rounded rounded-full hover:bg-gray-100 hover:border-pink-500 hover:text-pink-500 focus:outline-none focus:ring"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-3 h-3 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      />
                    </svg>
                    <span class="text-sm font-medium">Tableau de bord</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </body>
      </div>
      <div class="text-center py-4  text-gray-100 bg-blue-800 font-medium ">
        &copy;2023 CopyRight. Tout droit réservé WABAKIT.
      </div>
    </>
  );
}

export default Endpoint;
