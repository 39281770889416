import Headboard from "./Headboard";
import CountdownTimer from "./CountdownTimer";
// import Localisation from "./Locatisation";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function Getcode() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [notifications, setNotifications] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [msgsuccess, setMmsgsuccess] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [displayCountry, setDisplayCountry] = useState("");
  const [displayCode, setDisplayCode] = useState("En attente...");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  let Our_country = localStorage.getItem("vpn");
  let Our_number = localStorage.getItem("xtram");
  let number_service = localStorage.getItem("count");
  let number_tzid = localStorage.getItem("zoom");

  // console.log(Our_country, Our_number, number_service, number_tzid);

  //   console.log(displayCountry);

  //Get Validation Code

  //Function copy
  const [copySuccess, setCopySuccess] = useState("");
  const [copyCodeSuccess, setCopyCodeSuccess] = useState("");

  const textAreaRef = useRef(null);
  const textCode = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copié !");
    setTimeout(() => {
      setCopySuccess("");
    }, "2000");
  }

  function copyCodeToClipboard(e) {
    textCode.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopyCodeSuccess("✔️Copié !");
    setTimeout(() => {
      setCopyCodeSuccess("");
    }, "2000");
  }

  function getCode() {
    if (Our_number === null) {
      setErrormsg(
        <p className="rounded-lg justify-center bg-red-200 p-4">
          Pas de numéro disponible !!{" "}
          <a
            className="border rounded-lg p-1 mt-1 bg-gray-100"
            href="../home/dashboard"
          >
            Commander à nouveau ⇾
          </a>
        </p>
      );
      setLoading(false);
      return null;
    }
    try {
      setLoading(true);
      let data = JSON.stringify({
        UserEmail: "getcode@wabakit",
        service: number_service,
        countryCode: Our_country,
        price: 0,
        numero: Number(Our_number),
        code_tzid: Number(number_tzid),
        MsgCode: "code XXXXX : XXXXXXXX",
      });
      // console.log(data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.React_App_base_url + "getnumbers/validationcode",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
        data: data,
      };

      axios.request(config).then((response) => {
        if (response) {
          // console.log(JSON.stringify(response.data));
          // console.log(response.data);
          const CodeReceive = response.data.response;
          const MsgReceive = response.data.message;
          // console.log(CodeReceive, MsgReceive);
          if (CodeReceive === "TZ_NUM_WAIT") {
            setErrormsg(
              <p className="rounded-lg justify-center bg-yellow-200 p-4">
                Attention, Votre numéro n'est pas encore utilisé et est en attente du code SMS et sera bientôt
                indisponible ! {MsgReceive}  N'oubliez pas de cliquez à nouveau sur le bouton ci-dessous et patienter...
              </p>
            );
            setLoading(false);
            setTimeout(() => {
              setErrormsg("");
            }, "5000");
          }
          // console.log(response.data.code[0].msg);
          const Noresponse = response.data.code;

          if (response.data === "") {
            setErrormsg(
              <p className="rounded-lg justify-center bg-red-200 p-4">
                Le delai de validité de votre numéro est expiré. Vous pouvez
                commander un autre maintenant !{" "}
                <a
                  className="border rounded-lg p-1 mt-1 bg-gray-100"
                  href="../home/dashboard"
                >
                  Commander à nouveau ⇾
                </a>
              </p>
            );
            setLoading(false);
              let codePass = localStorage.getItem("pass");
              let codeTzid = localStorage.getItem("zoom");
            setTimeout(() => {
              // let ConfirmDisburs = localStorage.getItem("noconnexion");
              // if (ConfirmDisburs===null) {

              // }
              // console.log("Hello");
            
              // console.log(codePass, response.data);
              if (codePass === null && response.data === "") {
                //Get Number data
                axios.get(
                    process.env.React_App_base_url +
                      `getnumbers/bytzid/` +
                      codeTzid,
                    {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Credentials": true,
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${isAuthenticated}`,
                      },
                    }
                  )
                  .then((res) => {
                    // console.log(res.data);
                    let Disbprice = res.data.Number.price;
                    let smsCode = res.data.Number.MsgCode;
                    let StatusDisburs = res.data.Number.Disburs;
                    let NumberID = res.data.Number.id;
                    // console.log(StatusDisburs);
                    //Get userInfos
                    if (StatusDisburs === "no") {
                      //Confirm Disburssement

                      let StateId = atob(localStorage.getItem("isLoggedIn"));
                      if (StateId && smsCode === "code: XXXX") {
                        axios
                          .get(
                            process.env.React_App_base_url + `users/` + StateId,
                            {
                              headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Access-Control-Allow-Credentials": true,
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${isAuthenticated}`,
                              },
                            }
                          )
                          .then((res) => {
                            //  console.log(res.data.data);
                            const userData = res.data.data;
                            // setUserName(userData.pseudo);
                            // setNotifications(userData.notify);
                            const balance = userData.money;
                            // setUserEmail(userData.email);

                            const data = JSON.stringify({
                              money: Number(Disbprice) + Number(balance),
                            });

                            //Resboursement
                            axios
                              .patch(
                                process.env.React_App_base_url +
                                  `users/` +
                                  StateId,
                                data,
                                {
                                  headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Credentials": true,
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${isAuthenticated}`,
                                  },
                                }
                              )
                              .then((res) => {
                                // console.log(res);
                                const mydata = JSON.stringify({
                                  Disburs: "yes",
                                });

                                //Resboursement
                                axios
                                  .patch(
                                    process.env.React_App_base_url +
                                      `getnumbers/` +
                                      NumberID,
                                    mydata,
                                    {
                                      headers: {
                                        "Access-Control-Allow-Origin": "*",
                                        "Access-Control-Allow-Credentials": true,
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${isAuthenticated}`,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    //Confirm Disburssement
                                    // console.log(res);
                                  });
                                navigate("../home/dashboard");
                              });

                            setLoading(false);

                            setErrormsg(
                              <p className="rounded-lg text-center bg-red-200 p-4">
                                {resMessage}
                              </p>
                            );
                            setTimeout(() => {
                              setErrormsg("");
                            }, "3000");
                            // }
                          });
                      }
                    } else if (
                      StatusDisburs === "" ||
                      StatusDisburs === "yes"
                    ) {
                      return null;
                    }
                  });
              }
            }, 1000);
          }
          if (response.data.message === "Veuillez patienter...") {
            setTimeout(() => {
              getCode();
            }, "1000");
          }

          const resMessage = response.data.message;

          if (response.data.message === "Votre code validation") {
            let CodeExist = response.data.code;
            // console.log(response.data.code[0].msg);
            localStorage.setItem("pass", CodeExist);
            // Lecture du code en audio
            // Vérifiez si le navigateur supporte l'API Web Speech
            if ("speechSynthesis" in window) {
              // Créez une nouvelle instance de SpeechSynthesisUtterance
              let utterance = new SpeechSynthesisUtterance();

              // Définissez le texte que vous voulez lire
              utterance.text =
                "Bonjour, Votre code de validation est arrivé. Veuillez noter " +
                CodeExist+""+"Merci pour la confiance !";

              // Définissez la langue (optionnel)
              utterance.lang = "fr-FR";

              // Lisez le texte
              window.speechSynthesis.speak(utterance);
            } else {
              // console.log(
              //   "L'API Web Speech n'est pas supportée par ce navigateur."
              // );
            }

            // Lecture du code en audio

            setDisplayCode(CodeExist);
            setMmsgsuccess(
              <p className="rounded-lg justify-center text-center bg-green-200 p-4">
                Bravo 🤩🤩! Votre code de validation SMS est arrivé 👇.
                <a
                  className="border rounded-lg p-1 mt-1 bg-gray-100"
                  href="../home/dashboard"
                >
                  Commander à nouveau ⇾
                </a>
              </p>
            );
            setLoading(false);
            // window.location.reload();
          }

         
        } else {
          setErrormsg(
            "Désolé, une erreur est survenue, veuillez rafraichir la page et rééssayer  !"
          );
        }
      });
    } catch (e) {
      setLoading(false);
      // console.log(e);
    }
  }

  useEffect(() => {
    setTimeout(() => {
       if (Our_country === "229") {
         setDisplayCountry("Bénin");
       }

       if (Our_country === "225") {
         setDisplayCountry("Côte d'Ivoire");
       }
       if (Our_country === "221") {
         setDisplayCountry("Sénégal");
       }
       if (Our_country === "234") {
         setDisplayCountry("Nigeria");
       }
       if (Our_country === "226") {
         setDisplayCountry("Burkina Faso");
       }
       if (Our_country === "241") {
         setDisplayCountry("Gabon");
       }
       if (Our_country === "243") {
         setDisplayCountry("Congo Kinshassa");
       }

       if (Our_country === "254") {
         setDisplayCountry("Kenya");
       }
       if (Our_country === "233") {
         setDisplayCountry("Ghana");
       }
      if (Our_country === "7") {
        setDisplayCountry("Russie");
      }
      if (Our_country === "49") {
        setDisplayCountry("Allemagne");
      }
      if (Our_country === "33") {
        setDisplayCountry("France");
      }
      if (Our_country === "32") {
        setDisplayCountry("France");
      }
      if (Our_country === "31") {
        setDisplayCountry("Pays Bas");
      }
      if (Our_country === "1000") {
        setDisplayCountry("Canada");
      }
      if (Our_country === "1") {
        setDisplayCountry("Usa");
      }
      if (Our_country === "55") {
        setDisplayCountry("Brésil");
      }
      if (Our_country === "358") {
        setDisplayCountry("Finlande");
      }
      if (Our_country === "41") {
        setDisplayCountry("Suisse");
      }
      if (Our_country === "34") {
        setDisplayCountry("Espagne");
      }
      if (Our_country === "86") {
        setDisplayCountry("Chine");
      }
      if (Our_country === "44") {
        setDisplayCountry("Angleterre");
      }
      if (Our_country === "36") {
        setDisplayCountry("Hongrie");
      }
      if (Our_country === "61") {
        setDisplayCountry("Australie");
      }

      if (Our_country === "53") {
        setDisplayCountry("Cuba");
      }
      if (Our_country === "39") {
        setDisplayCountry("Italie");
      }
      if (Our_country === "57") {
        setDisplayCountry("Colombie");
      }
      if (Our_country === "30") {
        setDisplayCountry("Grèce");
      }

      if (Our_country === "966") {
        setDisplayCountry("Arabie Saoudite");
      }

      if (Our_country === "352") {
        setDisplayCountry("Luxembourg");
      }
      if (Our_country === "90") {
        setDisplayCountry("Turquie");
      }
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setNotifications(userData.notify);
              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    // return null;
  };

  return (
    <>
      <Headboard />
      <div class="py-10">
        <div class="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
            <nav
              aria-label="Sidebar"
              class="sticky top-4 divide-y divide-gray-300"
            >
              <div class="space-y-1 pb-8 ">
                <a
                  href="./dashboard"
                  class="bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    class="text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    x-description="Heroicon name: outline/home"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    ></path>
                  </svg>
                  <span class="truncate">{t("Tableau de bord")} </span>
                </a>

                <a
                  href="./profil"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M19 13h7v2h-7zm0-5h11v2H19zm0-5h11v2H19zm-8 27H7a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.947 2.947 0 0 1 3-3h6a2.947 2.947 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2M6 12a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1zm3-3a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4m0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2"
                    />
                  </svg>
                  <span class="truncate">{t("Profil")} </span>
                </a>

                {/* <a
                  href="./enumber"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V128L256 0H64C28.7 0 0 28.7 0 64m224 192h-64v-64h64zm96 0h-64v-64h32c17.7 0 32 14.3 32 32zm-64 128h64v32c0 17.7-14.3 32-32 32h-32zm-96 0h64v64h-64zm-96 0h64v64H96c-17.7 0-32-14.3-32-32zm0-96h256v64H64zm0-64c0-17.7 14.3-32 32-32h32v64H64z"
                    />
                  </svg>
                  <span class="truncate">{t("E-numéro SMS")} </span>
                </a> */}

                <a
                  href="./recharges"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m17.371 18.587l-.656-.656q-.128-.13-.306-.13t-.307.13t-.129.304t.129.303l.86.86q.186.187.419.187t.419-.187l2.098-2.067q.129-.13.139-.297t-.139-.317q-.129-.129-.316-.129t-.317.13zm-.64-9.856q.213 0 .356-.143t.144-.357t-.144-.357t-.356-.143H7.269q-.213 0-.356.143t-.144.357t.144.357t.356.143zM18 22.115q-1.671 0-2.836-1.164T14 18.115t1.164-2.835T18 14.115t2.836 1.165T22 18.115t-1.164 2.836T18 22.115M5.615 4h12.77q.67 0 1.143.472q.472.472.472 1.143v5.945q-.892-.293-1.828-.301t-1.845.241H7.269q-.213 0-.356.143T6.769 12t.144.357t.356.143h6.812q-.752.521-1.326 1.223t-.945 1.546H7.27q-.214 0-.357.144t-.144.356t.144.357t.356.143h4.21q-.108.404-.168.815t-.061.858q0 .685.143 1.359q.144.674.43 1.299q-.029.006-.06-.009t-.055-.037l-.82-.57q-.111-.072-.234-.072t-.235.073l-.877.607q-.111.073-.234.073t-.235-.073l-.877-.607q-.111-.073-.234-.073t-.235.073l-.877.607q-.112.073-.235.073t-.234-.073l-.877-.607q-.112-.073-.235-.073t-.234.073l-.781.607q-.058.039-.254.13V5.614q0-.67.472-1.143Q4.944 4 5.615 4"
                    />
                  </svg>
                  <span class="truncate">{t("Mes Recharges")} </span>
                </a>

                <a
                  href="./commandes"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m24.822 32.166l-9.593 5.168l-9.592-5.168V42.5h19.185zm-5.28-3.891l5.28 3.891m-19.185 0l9.592-7.069l2.467 1.817m4.796-15.658h15.349M22.492 15.81h15.349m-15.349 4.555h9.73M39.767 5.5H20.292a2.596 2.596 0 0 0-2.596 2.596V30.12l4.065-4.065h18.006a2.596 2.596 0 0 0 2.596-2.596h0V8.096A2.596 2.596 0 0 0 39.767 5.5"
                    />
                  </svg>
                  <span class="truncate">{t("Historiques")} </span>
                </a>
              </div>
              <div class="pt-10">
                <button
                  onClick={handleLogout}
                  class="flex px-3 text-sm font-medium text-gray-900"
                  id="communities-headline font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22m7-6v-3h-8v-2h8V8l5 4z"
                    />
                  </svg>
                  {t("Déconnexion")}
                </button>
              </div>
            </nav>
          </div>

          <main class="w-full lg:col-span-9 xl:col-span-48 justify-center items-center mx-auto px-8">
            {/* <p class="font-bold text-3xl mx-auto text-center">
              Rechargez votre
              <span class="text-green-500 mx-1 font-extrabold text-4xl relative inline-block stroke-current">
                comptes via
                <svg
                  class="absolute -bottom-0.5 w-full max-h-1.5"
                  viewBox="0 0 55 5"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
                    stroke-width="2"
                  ></path>
                </svg>
              </span>
              ....MTN BENIN, MOOV BENIN, VISACARD, TMONEY TOGO, FLOOZ TOGO
            </p> */}
            {/* <Localisation /> */}
            <section class="flex gap-8 -mt-8 py-10 my-auto dark:bg-gray-900">
              <div class="max-w-xl h-48 mx-auto mt-2 flex w-full flex-col border rounded-lg bg-yellow-500 p-8">
                <div className="rounded-lg bg-[url('https://wallpapers.com/images/hd/blockchain-background-72089b01c392a92a.jpg')] bg-cover bg-center bg-no-repeat items-center">
                  <h2 class="title-font mb-1 p-4 text-center text-lg font-bold text-xl text-gray-100 ">
                    {t("Votre numéro de validation de compte")}
                  </h2>

                  <span class="bg-blue-100 flex mx-8  gap-5 my-8 items-center justify-between py-3 px-5 rounded-full">
                    <code class="text-blue-900 p-4  text-left whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {" "}
                      <input
                        value={Our_number}
                        ref={textAreaRef}
                        className="font-bold -ml-8 outline-0 bg-blue-100 text-center justify-center mx-auto text-gray-900 text-xl"
                      ></input>
                      {/* <span className="font-bold text-center justify-center mx-auto text-gray-900 text-2xl">
                        {Our_number}
                      </span> */}
                      <span className="font-bold text-center justify-center mx-auto -ml-12 text-gray-900 text-2xl">
                        {" "}
                        | {displayCountry}
                      </span>
                    </code>
                    <span class="text-blue-900">
                      {copySuccess}
                      <button onClick={copyToClipboard}>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                            fill="currentColor"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </button>
                    </span>
                  </span>
                  <p className="font-bold text-center  text-gray-100 text-xl">
                    {" "}
                    <CountdownTimer />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 text-center mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <rect
                        width="10"
                        height="10"
                        x="1"
                        y="1"
                        fill="currentColor"
                        rx="1"
                      >
                        <animate
                          id="svgSpinnersBlocksShuffle30"
                          fill="freeze"
                          attributeName="x"
                          begin="0;svgSpinnersBlocksShuffle3b.end"
                          dur="0.2s"
                          values="1;13"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle31"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle38.end"
                          dur="0.2s"
                          values="1;13"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle32"
                          fill="freeze"
                          attributeName="x"
                          begin="svgSpinnersBlocksShuffle39.end"
                          dur="0.2s"
                          values="13;1"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle33"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle3a.end"
                          dur="0.2s"
                          values="13;1"
                        />
                      </rect>
                      <rect
                        width="10"
                        height="10"
                        x="1"
                        y="13"
                        fill="currentColor"
                        rx="1"
                      >
                        <animate
                          id="svgSpinnersBlocksShuffle34"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle30.end"
                          dur="0.2s"
                          values="13;1"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle35"
                          fill="freeze"
                          attributeName="x"
                          begin="svgSpinnersBlocksShuffle31.end"
                          dur="0.2s"
                          values="1;13"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle36"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle32.end"
                          dur="0.2s"
                          values="1;13"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle37"
                          fill="freeze"
                          attributeName="x"
                          begin="svgSpinnersBlocksShuffle33.end"
                          dur="0.2s"
                          values="13;1"
                        />
                      </rect>
                      <rect
                        width="10"
                        height="10"
                        x="13"
                        y="13"
                        fill="currentColor"
                        rx="1"
                      >
                        <animate
                          id="svgSpinnersBlocksShuffle38"
                          fill="freeze"
                          attributeName="x"
                          begin="svgSpinnersBlocksShuffle34.end"
                          dur="0.2s"
                          values="13;1"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle39"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle35.end"
                          dur="0.2s"
                          values="13;1"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle3a"
                          fill="freeze"
                          attributeName="x"
                          begin="svgSpinnersBlocksShuffle36.end"
                          dur="0.2s"
                          values="1;13"
                        />
                        <animate
                          id="svgSpinnersBlocksShuffle3b"
                          fill="freeze"
                          attributeName="y"
                          begin="svgSpinnersBlocksShuffle37.end"
                          dur="0.2s"
                          values="1;13"
                        />
                      </rect>
                    </svg>{" "}
                    Service : {number_service}
                  </p>
                  <p class="mb-5 leading-relaxed text-yellow-300 p-4">
                    {t(
                      "Veuillez copier et coller ce numéro ci-dessus dans la fenêtre de numéro de réception de code de validation de l'application ou site en question."
                    )}
                  </p>
                </div>

                <div class="mb-4">
                  <label
                    for="number"
                    class="text-sm leading-7 my-8 text-center font-bold text-gray-600"
                  >
                    {t(
                      "Votre code s'affichera dans le champs ci-dessous dans un instant en cliquant sur le button recevoir le code maintenant"
                    )}
                  </label>
                </div>
                <div class="mb-4">
                  <label for="message" class="text-sm leading-7 text-gray-600">
                    {/* Message */}
                  </label>
                  <div className="text-red-700 p-4">{errormsg} </div>
                  <div className="text-green-700 p-4">{msgsuccess} </div>

                  <div
                    id="message"
                    name="message"
                    class="bg-[url('https://img.freepik.com/premium-vector/online-web-internet-digital-money-transaction-by-terminal-smartphone-illustration_133260-1107.jpg?size=626&ext=jpg')] bg-cover bg-center bg-no-repeat h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                  >
                    <p class="mx-auto text-center">{copyCodeSuccess}</p>
                    <span class="bg-blue-100 flex gap-5 my-8 items-center justify-between py-3 px-5 rounded-full">
                      <code class="text-blue-900 text-left whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {t("votre code  est:")}{" "}
                        <input
                          ref={textCode}
                          value={displayCode}
                          className="font-bold outline-0 border-0 bg-blue-100 animate-pulse text-gray-900 text-xl"
                        ></input>
                      </code>

                      <span class="text-blue-900">
                        <button onClick={copyCodeToClipboard}>
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                              fill="currentColor"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </span>
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => {
                    getCode();
                  }}
                  enable={loading}
                  class="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                      />
                      <rect
                        width="2"
                        height="7"
                        x="11"
                        y="6"
                        fill="currentColor"
                        rx="1"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="9s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        />
                      </rect>
                      <rect
                        width="2"
                        height="9"
                        x="11"
                        y="11"
                        fill="currentColor"
                        rx="1"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        />
                      </rect>
                    </svg>
                  ) : (
                    ""
                  )}
                  {t("Recevoir le code maintenant ⇾")}
                </button>
                <p class="mt-3 text-xs text-yellow-600">
                  {t(
                    "*NB: Veuillez cliquer et patienter pour recevoir le code de validation du compte en question. Après 15 min, le numéro n'est plus disponible et si après vérification le code n'a pas été envoyé, vous êtes automatiquement remboursé !"
                  )}
                </p>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
export default Getcode;
