import Headadmin from "./Headadmin";
import Charts from "./Charts";
import Footer from "./Footerboard";
import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
 import "jquery/dist/jquery.min.js"; 
 //Datatable Modules
 import "datatables.net-dt/js/dataTables.dataTables";
 import $ from "jquery";



function Dashadmin() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [totalPay, setTotalPay] = useState();
  const [totalNumbers, setTotalNumbers] = useState();
  const [dataPayments, setDataPayments] = useState([]);
  const [numbersData, setNumbersData] = useState([]);


  const [totalVente, setTotalVente] = useState();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              // setUserName(userData.pseudo);
              // setMoney(userData.money);
              // setMybonus(userData.bonus);
              // setUserEmail(userData.email);

              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;
              let role=res.data.data.role;
              if (ActivationCompte !== true ) {
                localStorage.clear();
                navigate(".././login");
              }

              if (role !== "admin") {
                localStorage.clear();
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
    //Total Dépots
    axios
      .get(process.env.React_App_base_url + `payments/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        //  console.log(res.data.payment);
        // let Deposite = res.data.payment;
        // console.log(Deposite);
        let Deposite = res.data.payment.filter(
          (confirm) => confirm["paymentStatus"] === "confirmed"
        );
      let goNumber=  Deposite.slice((Deposite.length - 3), Deposite.length);
      // console.log(goNumber);
        

        // Get Total Payment
        if (Deposite) {
          setDataPayments(goNumber);
          let availableConfirm = res.data.payment
            .filter((confirm) => confirm["paymentStatus"] === "confirmed")
            .map((item) => item.montant)
            .reduce((a, b) => a + b, 0);

          setTotalPay(availableConfirm);
        }
      });
    //Total Ventes

    axios
      .get(process.env.React_App_base_url + `getnumbers/`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        //  console.log(res.data.Numbers);
        let Vente = res.data.Numbers ;
        let forFilter = Vente.filter((number) => number["Disburs"] === "no");
        let DataVente = forFilter.slice(forFilter.length - 2, forFilter.length);
        // console.log(DataVente);
        setNumbersData(DataVente);

        let nbreNumbers = res.data.Numbers.length;
        setTotalVente(nbreNumbers);
        // Get Total Payment
        let availableConfirm = res.data.Numbers.filter(
          (number) => number["Disburs"] === "no"
        )
          .map((item) => item.price)
          .reduce((a, b) => a + b, 0);

        setTotalNumbers(availableConfirm);
      });
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 3000);
  }, []);

  return (
    <>
      <Headadmin />
      <div class="py-10 mb-64">
        <div class="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
            <nav
              aria-label="Sidebar"
              class="sticky top-4 divide-y divide-gray-300"
            >
              <div class="space-y-1 pb-8">
                <a
                  href="../home/dashadmin"
                  class="bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    class="text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    x-description="Heroicon name: outline/home"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    ></path>
                  </svg>
                  <span class="truncate">Administration</span>
                </a>

                <a
                  href="../home/utilisateurs"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M24 14.6c0 .6-1.2 1-2.6 1.2c-.9-1.7-2.7-3-4.8-3.9c.2-.3.4-.5.6-.8h.8c3.1-.1 6 1.8 6 3.5M6.8 11H6c-3.1 0-6 1.9-6 3.6c0 .6 1.2 1 2.6 1.2c.9-1.7 2.7-3 4.8-3.9zm5.2 1c2.2 0 4-1.8 4-4s-1.8-4-4-4s-4 1.8-4 4s1.8 4 4 4m0 1c-4.1 0-8 2.6-8 5c0 2 8 2 8 2s8 0 8-2c0-2.4-3.9-5-8-5m5.7-3h.3c1.7 0 3-1.3 3-3s-1.3-3-3-3c-.5 0-.9.1-1.3.3c.8 1 1.3 2.3 1.3 3.7c0 .7-.1 1.4-.3 2M6 10h.3C6.1 9.4 6 8.7 6 8c0-1.4.5-2.7 1.3-3.7C6.9 4.1 6.5 4 6 4C4.3 4 3 5.3 3 7s1.3 3 3 3"
                    />
                  </svg>
                  <span class="truncate">Utilisateurs</span>
                </a>

                <a
                  href="../home/depots"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      color="currentColor"
                    >
                      <path d="M20.943 16.835a15.76 15.76 0 0 0-4.476-8.616c-.517-.503-.775-.754-1.346-.986C14.55 7 14.059 7 13.078 7h-2.156c-.981 0-1.472 0-2.043.233c-.57.232-.83.483-1.346.986a15.76 15.76 0 0 0-4.476 8.616C2.57 19.773 5.28 22 8.308 22h7.384c3.029 0 5.74-2.227 5.25-5.165" />
                      <path d="M7.257 4.443c-.207-.3-.506-.708.112-.8c.635-.096 1.294.338 1.94.33c.583-.009.88-.268 1.2-.638C10.845 2.946 11.365 2 12 2s1.155.946 1.491 1.335c.32.37.617.63 1.2.637c.646.01 1.305-.425 1.94-.33c.618.093.319.5.112.8l-.932 1.359c-.4.58-.599.87-1.017 1.035S13.837 7 12.758 7h-1.516c-1.08 0-1.619 0-2.036-.164S8.589 6.38 8.189 5.8zm6.37 8.476c-.216-.799-1.317-1.519-2.638-.98s-1.53 2.272.467 2.457c.904.083 1.492-.097 2.031.412c.54.508.64 1.923-.739 2.304c-1.377.381-2.742-.214-2.89-1.06m1.984-5.06v.761m0 5.476v.764" />
                    </g>
                  </svg>
                  <span class="truncate">Dépôts</span>
                </a>

                <a
                  href="../home/allcommandes"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                    />
                  </svg>
                  <span class="truncate">Commandes</span>
                </a>
              </div>
              <div class="pt-10">
                <button
                  onClick={handleLogout}
                  class="flex px-3 text-sm font-medium text-gray-900"
                  id="communities-headline font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22m7-6v-3h-8v-2h8V8l5 4z"
                    />
                  </svg>
                  {t("Déconnexion")}
                </button>
              </div>
            </nav>
          </div>
          <main class="lg:col-span-9 xl:col-span-6">
            <div class="px-4 sm:px-0">
              <div class="">
                <div class="mt-10 pb-1">
                  <div class="relative">
                    <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                    <div class="relative max-w-7xl mx-auto ">
                      <div class="max-w-4xl mx-auto">
                        <dl class="rounded-lg bg-white -mt-8 shadow-lg sm:grid sm:grid-cols-3">
                          <div class="flex flex-col border-b bg-yellow-200  border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                            <dd class="order-1 text-2xl font-extrabold text-yellow-700">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8 -mb-8"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M4.892 9.614c0-.402.323-.728.722-.728H9.47c.4 0 .723.326.723.728a.726.726 0 0 1-.723.729H5.614a.726.726 0 0 1-.722-.729"
                                />
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M21.188 10.004c-.062-.004-.13-.004-.2-.004h-2.773C15.944 10 14 11.736 14 14c0 2.264 1.944 4 4.215 4h2.773c.07 0 .138 0 .2-.004c.923-.056 1.739-.757 1.808-1.737c.004-.064.004-.133.004-.197v-4.124c0-.064 0-.133-.004-.197c-.069-.98-.885-1.68-1.808-1.737m-3.217 5.063c.584 0 1.058-.478 1.058-1.067c0-.59-.474-1.067-1.058-1.067c-.585 0-1.06.478-1.06 1.067c0 .59.475 1.067 1.06 1.067"
                                  clip-rule="evenodd"
                                />
                                <path
                                  fill="currentColor"
                                  d="M21.14 10.002c0-1.181-.044-2.448-.798-3.355a3.773 3.773 0 0 0-.233-.256c-.749-.748-1.698-1.08-2.87-1.238C16.099 5 14.644 5 12.806 5h-2.112C8.856 5 7.4 5 6.26 5.153c-1.172.158-2.121.49-2.87 1.238c-.748.749-1.08 1.698-1.238 2.87C2 10.401 2 11.856 2 13.694v.112c0 1.838 0 3.294.153 4.433c.158 1.172.49 2.121 1.238 2.87c.749.748 1.698 1.08 2.87 1.238c1.14.153 2.595.153 4.433.153h2.112c1.838 0 3.294 0 4.433-.153c1.172-.158 2.121-.49 2.87-1.238a3.81 3.81 0 0 0 .526-.66c.45-.72.504-1.602.504-2.45l-.15.001h-2.774C15.944 18 14 16.264 14 14c0-2.264 1.944-4 4.215-4h2.773c.052 0 .103 0 .151.002"
                                  opacity="0.5"
                                />
                                <path
                                  fill="currentColor"
                                  d="M10.101 2.572L8 3.992l-1.733 1.16C7.405 5 8.859 5 10.694 5h2.112c1.838 0 3.294 0 4.433.153c.23.03.45.068.662.114L16 4l-2.113-1.428a3.418 3.418 0 0 0-3.786 0"
                                />
                              </svg>{" "}
                              {totalPay}
                            </dd>
                          </div>
                          <div class="flex flex-col border-t bg-green-200  border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                            <dd class="order-1 text-2xl text-green-700 font-extrabold text-gray-700">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8 -mb-8"
                                viewBox="0 0 128 128"
                              >
                                <path
                                  fill="#ffca28"
                                  d="M93.46 39.45c6.71-1.49 15.45-8.15 16.78-11.43c.78-1.92-3.11-4.92-4.15-6.13c-2.38-2.76-1.42-4.12-.5-7.41c1.05-3.74-1.44-7.87-4.97-9.49s-7.75-1.11-11.3.47s-6.58 4.12-9.55 6.62c-2.17-1.37-5.63-7.42-11.23-3.49c-3.87 2.71-4.22 8.61-3.72 13.32c1.17 10.87 3.85 16.51 8.9 18.03c6.38 1.92 13.44.91 19.74-.49"
                                />
                                <path
                                  fill="#e2a610"
                                  d="M104.36 8.18c-.85 14.65-15.14 24.37-21.92 28.65l4.4 3.78s2.79.06 6.61-1.16c6.55-2.08 16.12-7.96 16.78-11.43c.97-5.05-4.21-3.95-5.38-7.94c-.61-2.11 2.97-6.1-.49-11.9m-24.58 3.91s-2.55-2.61-4.44-3.8c-.94 1.77-1.61 3.69-1.94 5.67c-.59 3.48 0 8.42 1.39 12.1c.22.57 1.04.48 1.13-.12c1.2-7.91 3.86-13.85 3.86-13.85"
                                />
                                <path
                                  fill="#ffca28"
                                  d="M61.96 38.16S30.77 41.53 16.7 68.61s-2.11 43.5 10.55 49.48c12.66 5.98 44.56 8.09 65.31 3.17s25.94-15.12 24.97-24.97c-1.41-14.38-14.77-23.22-14.77-23.22s.53-17.76-13.25-29.29c-12.23-10.24-27.55-5.62-27.55-5.62"
                                />
                                <path
                                  fill="#6b4b46"
                                  d="M74.76 83.73c-6.69-8.44-14.59-9.57-17.12-12.6c-1.38-1.65-2.19-3.32-1.88-5.39c.33-2.2 2.88-3.72 4.86-4.09c2.31-.44 7.82-.21 12.45 4.2c1.1 1.04.7 2.66.67 4.11c-.08 3.11 4.37 6.13 7.97 3.53c3.61-2.61.84-8.42-1.49-11.24c-1.76-2.13-8.14-6.82-16.07-7.56c-2.23-.21-11.2-1.54-16.38 8.31c-1.49 2.83-2.04 9.67 5.76 15.45c1.63 1.21 10.09 5.51 12.44 8.3c4.07 4.83 1.28 9.08-1.9 9.64c-8.67 1.52-13.58-3.17-14.49-5.74c-.65-1.83.03-3.81-.81-5.53c-.86-1.77-2.62-2.47-4.48-1.88c-6.1 1.94-4.16 8.61-1.46 12.28c2.89 3.93 6.44 6.3 10.43 7.6c14.89 4.85 22.05-2.81 23.3-8.42c.92-4.11.82-7.67-1.8-10.97"
                                />
                                <path
                                  fill="none"
                                  stroke="#6b4b46"
                                  stroke-miterlimit="10"
                                  stroke-width="5"
                                  d="M71.16 48.99c-12.67 27.06-14.85 61.23-14.85 61.23"
                                />
                                <path
                                  fill="#6d4c41"
                                  d="M81.67 31.96c8.44 2.75 10.31 10.38 9.7 12.46c-.73 2.44-10.08-7.06-23.98-6.49c-4.86.2-3.45-2.78-1.2-4.5c2.97-2.27 7.96-3.91 15.48-1.47"
                                />
                                <path
                                  fill="#6b4b46"
                                  d="M81.67 31.96c8.44 2.75 10.31 10.38 9.7 12.46c-.73 2.44-10.08-7.06-23.98-6.49c-4.86.2-3.45-2.78-1.2-4.5c2.97-2.27 7.96-3.91 15.48-1.47"
                                />
                                <path
                                  fill="#e2a610"
                                  d="M96.49 58.86c1.06-.73 4.62.53 5.62 7.5c.49 3.41.64 6.71.64 6.71s-4.2-3.77-5.59-6.42c-1.75-3.35-2.43-6.59-.67-7.79"
                                />
                              </svg>{" "}
                              {totalNumbers}
                            </dd>
                          </div>
                          <div class="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                            <dd class="order-1 text-2xl font-extrabold text-gray-700">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-8 h-8 -mb-8"
                                viewBox="0 0 48 48"
                              >
                                <path
                                  fill="#009688"
                                  d="M36 45H12c-2.2 0-4-1.8-4-4V7c0-2.2 1.8-4 4-4h16.3c1.1 0 2.1.4 2.8 1.2l7.7 7.7c.8.8 1.2 1.8 1.2 2.8V41c0 2.2-1.8 4-4 4"
                                />
                                <path
                                  fill="#ff9800"
                                  d="M32 38H16c-1.1 0-2-.9-2-2V24c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2"
                                />
                                <path
                                  fill="#ffd54f"
                                  d="M29 30v3h5v2h-5v3h-2V22h2v6h5v2zm-15-1v2h5v2h-5v2h5v3h2v-9z"
                                />
                              </svg>{" "}
                              {totalVente}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hidden sm:block">
                <nav
                  class="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                  aria-label="Tabs"
                >
                  <p class="text-gray-900 rounded-l-lg  group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10">
                    <span>Total Dépôts</span>
                    <span
                      aria-hidden="true"
                      class="bg-rose-500 absolute inset-x-0 bottom-0 h-0.5"
                    ></span>
                  </p>

                  <p
                    class="text-gray-500 hover:text-gray-700   group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    x-state-description='undefined: "text-gray-900", undefined: "text-gray-500 hover:text-gray-700"'
                  >
                    <span>Total Ventes</span>
                    <span
                      aria-hidden="true"
                      class="bg-rose-500 absolute inset-x-0 bottom-0 h-0.5"
                    ></span>
                  </p>

                  <p
                    class="text-gray-500 hover:text-gray-700  rounded-r-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    x-state-description='undefined: "text-gray-900", undefined: "text-gray-500 hover:text-gray-700"'
                  >
                    <span>Total numéros vendus</span>
                    <span
                      aria-hidden="true"
                      class="bg-rose-500 absolute inset-x-0 bottom-0 h-0.5"
                    ></span>
                  </p>
                </nav>
              </div>
            </div>
            <div class="mt-4 ">
              <h1 class="text-center font-bold mb-4">DERNIERS DEPOTS</h1>
              <div class="shadow-lg rounded-lg overflow-hidden">
                <table class="w-full table-fixed">
                  <thead>
                    <tr class="bg-gray-100">
                      <th class="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Nom
                      </th>
                      <th class="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Phone
                      </th>
                      <th class="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Montant XOF
                      </th>
                      <th class="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Status
                      </th>
                      <th class="w-1/4 py-4 px-6 text-left text-gray-600 font-bold uppercase">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    {dataPayments.map((payment) => (
                      <tr key={payment.id}>
                        <td class="py-4 px-6 border-b border-gray-200">
                          {payment.pseudo}
                        </td>
                        <td class="py-4 px-6 border-b border-gray-200">
                          {payment.userPhone}
                        </td>
                        <td class="py-4 px-6 border-b border-gray-200">
                          {payment.montant}
                        </td>
                        <td class="py-4 px-6 border-b border-gray-200">
                          <span class="bg-green-500 text-white py-1 px-2 rounded-full text-xs">
                            {payment.paymentStatus}
                          </span>
                        </td>

                        <td class="py-4 px-6 border-b border-gray-200">
                          {payment.createdAt}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-6 -mb-16">
              <a
                href="../home/depots"
                class="block w-full rounded-md border text-white hover:text-blue-500 border-gray-300 bg-blue-500 px-4 py-2 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Ouvrir ⇾
              </a>
            </div>
          </main>
          <aside class="hidden xl:col-span-4 xl:block">
            <div class="sticky top-4 space-y-4">
              <section aria-labelledby="who-to-follow-heading">
                <div class="rounded-lg bg-white shadow">
                  <div class="p-6">
                    <h2
                      id="who-to-follow-heading"
                      class="text-base font-medium text-center bg-yellow-500 rounded-lg p-4 text-gray-100"
                    >
                      DERNIERS NUMEROS VENDUS & UTILISES
                    </h2>
                    <div class="mt-6 flow-root">
                      {numbersData.map((number) => (
                        <ul
                          key={number.id}
                          role="list"
                          class="-my-4 divide-y divide-gray-200"
                        >
                          <li class="flex items-center space-x-3 py-4">
                            <div class="flex-shrink-0">
                              {/* <img
                              class="h-8 w-8 rounded-full"
                              src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                              alt=""
                            /> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-8 w-8 rounded-full text-yellow-500"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M8 18.616q.214 0 .357-.144t.143-.357v-.423q0-.212-.144-.356t-.357-.144t-.356.144t-.143.356v.424q0 .212.144.356t.357.143m0-3.807q.213 0 .356-.144t.143-.356v-2.423q0-.213-.144-.357t-.357-.144t-.356.144t-.143.356v2.424q0 .212.144.356t.357.144m4 3.807q.213 0 .356-.143t.143-.357v-2.423q0-.212-.144-.356t-.357-.144t-.356.144t-.143.356v2.423q0 .213.144.357t.357.143m0-5.807q.213 0 .356-.144t.143-.356v-.424q0-.212-.144-.356t-.357-.144t-.356.144t-.143.356v.424q0 .212.144.356t.357.144m4 5.807q.212 0 .356-.143t.143-.357v-.423q0-.212-.144-.356t-.357-.144t-.356.144t-.143.356v.423q0 .213.144.357t.357.143m0-3.807q.213 0 .356-.144t.143-.356v-2.424q0-.212-.144-.356t-.357-.144t-.356.144t-.143.356v2.424q0 .212.144.356t.357.144M6.616 21q-.691 0-1.153-.462T5 19.385V9.095q0-.332.13-.633t.349-.518L9.944 3.48q.217-.218.518-.348T11.094 3h6.29q.691 0 1.153.462T19 4.615v14.77q0 .69-.463 1.152T17.385 21z"
                                />
                              </svg>
                            </div>

                            <div class="min-w-0 flex-1">
                              <ul class="text-sm font-medium text-gray-900">
                                <li className="">{number.numero}</li>
                                <li href="#">@{number.service}</li>
                                <li href="#">⌚️{number.updatedAt}</li>
                              </ul>
                            </div>

                            <div class="flex-shrink-0">
                              <button
                                type="button"
                                class="inline-flex items-center rounded-full bg-green-200 px-3 py-0.5 text-sm font-medium text-green-700 hover:bg-yellow-200"
                              >
                                <svg
                                  class="-ml-1 mr-0.5 h-5 w-5 text-green-400"
                                  x-description="Heroicon name: mini/plus"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>
                                </svg>
                                <span>Code sms reçu : {number.MsgCode}</span>
                              </button>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </div>
                    <div class="mt-6">
                      <a
                        href="../home/allcommandes"
                        class="block w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-center text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                      >
                        Voir +
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </aside>
        </div>
      </div>
      <div class="relative -mt-64 mb-8">
        <div class="flex items-center justify-center h-[50vh] px-2 sm:px-0">
          <div class="mt-6 m-auto space-y-6 w-full sm:w-8/12 md:w-7/12">
            <h1 class="text-center text-3xl sm:text-4xl md:text-5xl font-bold text-gray-700 dark:text-white">
              Statistique des opérations
            </h1>
            <p class="text-center text-sm sm:text-base md:text-xl text-gray-600 dark:text-gray-300">
              Numéros virtuels & services
            </p>
            <div class="flex flex-wrap justify-center gap-4 sm:gap-6">
              <a
                href=""
                // target="_blank"
                class="relative flex h-9 w-full items-center justify-center px-8 before:absolute 
                                  before:inset-0 before:rounded-full before:bg-teal-400 before:transition before:duration-300 
                                  hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span class="flex relative text-sm font-semibold text-white">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 1200 1200"
                    >
                      <path
                        fill="currentColor"
                        d="M600 0C308.74 0 66.009 207.555 11.499 482.812h166.553C229.37 297.756 398.603 161.719 600 161.719c121.069 0 230.474 49.195 309.668 128.613l-192.48 192.48H1200V0l-175.781 175.781C915.653 67.181 765.698 0 600 0M0 717.188V1200l175.781-175.781C284.346 1132.819 434.302 1200 600 1200c291.26 0 533.991-207.555 588.501-482.812h-166.553C970.631 902.243 801.396 1038.281 600 1038.281c-121.069 0-230.474-49.195-309.668-128.613l192.48-192.48z"
                      />
                    </svg>
                  </span>{" "}
                  <span className="-mt-1 ml-2">
                    {" "}
                    Statistique des ventes par mois
                  </span>
                </span>
              </a>
              {/* <a
                href=""
                // target="_blank"
                class="relative flex h-9 w-full items-center justify-center px-8 before:absolute 
                                  before:inset-0 before:rounded-full before:bg-teal-400 before:transition before:duration-300 
                                  hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span class="flex relative text-sm font-semibold text-white">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 1200 1200"
                    >
                      <path
                        fill="currentColor"
                        d="M600 0C308.74 0 66.009 207.555 11.499 482.812h166.553C229.37 297.756 398.603 161.719 600 161.719c121.069 0 230.474 49.195 309.668 128.613l-192.48 192.48H1200V0l-175.781 175.781C915.653 67.181 765.698 0 600 0M0 717.188V1200l175.781-175.781C284.346 1132.819 434.302 1200 600 1200c291.26 0 533.991-207.555 588.501-482.812h-166.553C970.631 902.243 801.396 1038.281 600 1038.281c-121.069 0-230.474-49.195-309.668-128.613l192.48-192.48z"
                      />
                    </svg>
                  </span>{" "}
                  <span className="-mt-1 ml-2"> Aujourd'hui</span>
                </span>
              </a>
              <a
                href=""
                // target="_blank"
                class="relative flex h-9 w-full items-center justify-center px-8 before:absolute 
                                  before:inset-0 before:rounded-full before:bg-teal-400 before:transition before:duration-300 
                                  hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
              >
                <span class="flex relative text-sm font-semibold text-white">
                  <span className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 1200 1200"
                    >
                      <path
                        fill="currentColor"
                        d="M600 0C308.74 0 66.009 207.555 11.499 482.812h166.553C229.37 297.756 398.603 161.719 600 161.719c121.069 0 230.474 49.195 309.668 128.613l-192.48 192.48H1200V0l-175.781 175.781C915.653 67.181 765.698 0 600 0M0 717.188V1200l175.781-175.781C284.346 1132.819 434.302 1200 600 1200c291.26 0 533.991-207.555 588.501-482.812h-166.553C970.631 902.243 801.396 1038.281 600 1038.281c-121.069 0-230.474-49.195-309.668-128.613l192.48-192.48z"
                      />
                    </svg>
                  </span>{" "}
                  <span className="-mt-1 ml-2"> 30 derniers jours</span>
                </span>
              </a>  */}
            </div>
          </div>
        </div>
      </div>
      <div className="justify-center -mt-48 ml-48 mr-48 mx-auto ">
        {" "}
        <Charts />
      </div>
      <div className="-mb-64 text-center mt-32 text-white bg-blue-500">
        <Footer />
      </div>
    </>
  );
}
export default Dashadmin;
