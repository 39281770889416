import Headboard from "./Headboard";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
 import "jquery/dist/jquery.min.js"; 
 //Datatable Modules
 import "datatables.net-dt/js/dataTables.dataTables";
 import $ from "jquery";
 import DataTable from "datatables.net-dt";
//  import "datatables.net-responsive-dt";
import { useTranslation } from "react-i18next";



function Recharges() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [notifications, setNotifications] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paymentsData, setPaymentsData] = useState([]);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const [rechargeId, setRechargeId] = useState("");
// var DataTable = require("datatables.net");
  

// let table = new DataTable("#example", {
//    responsive: true
// });

  const navigate = useNavigate();
const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setNotifications(userData.notify);
              setUserEmail(userData.email);
              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

//Update Notification
              var UpdateCompte = JSON.stringify({                
                notify: "0",
              });
              axios
                .patch(
                  process.env.React_App_base_url + `users/` + userID,
                  UpdateCompte,
                  {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Credentials": true,
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${isAuthenticated}`,
                    },
                  }
                )
                .then((res) => {
                  
                });


  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    // window.location.href = "../login";
    navigate("../login");

    return null;
  };

  useEffect(() => {
    setTimeout(
      () => {
        axios
          .get(process.env.React_App_base_url + `payments`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            // console.log(res);
            // setShowModal(false);
            const userData = res.data.payment;
            const filteredPayments = userData.filter(
              (userData) =>
                userData["userEmail"] === userEmail 
                // userData["paymentStatus"] === "PENDING"
            );
            // console.log(filteredAbonnements);

            setPaymentsData(filteredPayments);

            setPaymentsData(filteredPayments);
          });
      },
      3000,
      []
    );
  });
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 5000);
  }, []);

  return (
    <>
      <Headboard />
      <div class="py-10">
        <div class="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
            <nav
              aria-label="Sidebar"
              class="sticky top-4 divide-y divide-gray-300"
            >
              <div class="space-y-1 pb-8 ">
                <a
                  href="./dashboard"
                  class="bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    class="text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    x-description="Heroicon name: outline/home"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    ></path>
                  </svg>
                  <span class="truncate">{t("Tableau de bord")} </span>
                </a>

                <a
                  href="./profil"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M19 13h7v2h-7zm0-5h11v2H19zm0-5h11v2H19zm-8 27H7a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.947 2.947 0 0 1 3-3h6a2.947 2.947 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2M6 12a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1zm3-3a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4m0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2"
                    />
                  </svg>
                  <span class="truncate">{t("Profil")} </span>
                </a>

                {/* <a
                  href="./enumber"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V128L256 0H64C28.7 0 0 28.7 0 64m224 192h-64v-64h64zm96 0h-64v-64h32c17.7 0 32 14.3 32 32zm-64 128h64v32c0 17.7-14.3 32-32 32h-32zm-96 0h64v64h-64zm-96 0h64v64H96c-17.7 0-32-14.3-32-32zm0-96h256v64H64zm0-64c0-17.7 14.3-32 32-32h32v64H64z"
                    />
                  </svg>
                  <span class="truncate">{t("E-numéro SMS")} </span>
                </a> */}

                <a
                  href="./recharges"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m17.371 18.587l-.656-.656q-.128-.13-.306-.13t-.307.13t-.129.304t.129.303l.86.86q.186.187.419.187t.419-.187l2.098-2.067q.129-.13.139-.297t-.139-.317q-.129-.129-.316-.129t-.317.13zm-.64-9.856q.213 0 .356-.143t.144-.357t-.144-.357t-.356-.143H7.269q-.213 0-.356.143t-.144.357t.144.357t.356.143zM18 22.115q-1.671 0-2.836-1.164T14 18.115t1.164-2.835T18 14.115t2.836 1.165T22 18.115t-1.164 2.836T18 22.115M5.615 4h12.77q.67 0 1.143.472q.472.472.472 1.143v5.945q-.892-.293-1.828-.301t-1.845.241H7.269q-.213 0-.356.143T6.769 12t.144.357t.356.143h6.812q-.752.521-1.326 1.223t-.945 1.546H7.27q-.214 0-.357.144t-.144.356t.144.357t.356.143h4.21q-.108.404-.168.815t-.061.858q0 .685.143 1.359q.144.674.43 1.299q-.029.006-.06-.009t-.055-.037l-.82-.57q-.111-.072-.234-.072t-.235.073l-.877.607q-.111.073-.234.073t-.235-.073l-.877-.607q-.111-.073-.234-.073t-.235.073l-.877.607q-.112.073-.235.073t-.234-.073l-.877-.607q-.112-.073-.235-.073t-.234.073l-.781.607q-.058.039-.254.13V5.614q0-.67.472-1.143Q4.944 4 5.615 4"
                    />
                  </svg>
                  <span class="truncate underline">{t("Mes Recharges")} ⇾</span>
                </a>

                <a
                  href="./commandes"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m24.822 32.166l-9.593 5.168l-9.592-5.168V42.5h19.185zm-5.28-3.891l5.28 3.891m-19.185 0l9.592-7.069l2.467 1.817m4.796-15.658h15.349M22.492 15.81h15.349m-15.349 4.555h9.73M39.767 5.5H20.292a2.596 2.596 0 0 0-2.596 2.596V30.12l4.065-4.065h18.006a2.596 2.596 0 0 0 2.596-2.596h0V8.096A2.596 2.596 0 0 0 39.767 5.5"
                    />
                  </svg>
                  <span class="truncate">{t("Historiques")} </span>
                </a>
              </div>
              <div class="pt-10">
                <button
                  onClick={handleLogout}
                  class="flex px-3 text-sm font-medium text-gray-900"
                  id="communities-headline font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22m7-6v-3h-8v-2h8V8l5 4z"
                    />
                  </svg>
                  {t("Déconnexion")}
                </button>
              </div>
            </nav>
          </div>

          <main class="lg:col-span-9 xl:col-span-48 justify-center items-center mx-auto ">
            <p class="title-0 font-bold text-3xl">
              Historique des
              <span class="text-green-500 mx-1 font-extrabold text-4xl relative inline-block stroke-current">
                recharges
                <svg
                  class="absolute -bottom-0.5 w-full max-h-1.5"
                  viewBox="0 0 55 5"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
                    stroke-width="2"
                  ></path>
                </svg>
              </span>
              de votre compte
            </p>
            <section class="flex py-10 my-auto bg-gray-white  w-full -px-8">
              <table
                id="example"
                class="Tab divide-y bg-gray-300 w-full divide-gray-200"
              >
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Prénom(s)
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Montant
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Mode
                    </th>

                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Référence
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-gray-300 divide-y divide-gray-200">
                  {paymentsData.map((abData) => (
                    <tr key={abData.id}>
                      <td class="px-6 py-4 whitespace-nowrap">
                        {abData.pseudo}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        {abData.userEmail}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        {abData.montant}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">Mix</td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {abData.paymentStatus}
                        </span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-green-800">
                          {abData.createdAt}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button
                          // onClick={() => setRechargeId(abData.id)}
                          className="hover:text-pink-500 hover:bg-transparent text-gray-100 bg-yellow-500 rounded-lg border-2 border-transparent p-2"
                        >
                          {abData.payRef}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
export default Recharges;
