import logo from "./logo.svg";
// import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import sendEmail from "./SendEmail";
import { useNavigate, useLocation } from "react-router-dom";

function Passwordrecovery() {
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();



  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

 async function validateForm() {
   setLoading(true);
   // Perform operation and then set loading to false
   setTimeout(() => {
     setLoading(false);
   }, 500);

   if (email === "") {
     setErrormsg("Veuillez remplir tous les champs ! *");
     setTimeout(() => {
       setErrormsg("");
     }, 1000);
   }

   if (!isValidEmail(email)) {
     setErrormsg("Attention, Votre adresse E-mail est invalide !");
     //console.log(isValidEmail(email));
     setTimeout(() => {
       setErrormsg("");
     }, 1000);
   }
   var Emailconfirm = isValidEmail(email);

   if (email !== "" || Emailconfirm !== false) {
     
     await axios
       .post(process.env.React_App_base_url + "users/recover-password", {
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Access-Control-Allow-Credentials": true,
           "Content-Type": "application/json",
           //"Authorization": `Bearer ${localStorage.getItem("user-token")}`,
         },
         email,
       })
       //  .then((response) => response.text())
       .then((response) => {

        // console.log(response);
        //  console.log(response.data.userData);
         //  let resdata = JSON.parse(response);
         let resdata = response.data.userData;

         let userPhone = resdata.phone;
         let userName = resdata.pseudo;
         let userId = resdata.id;
         let userEmail = resdata.email;
         //  console.log((userEmail));
        // console.log(email, userEmail);
         if (userEmail) {
           //  console.log(userId);
           var length = 5;
           var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

           const userkey = Array.from({ length })
             .map(
               (e, i) =>
                 characters[Math.floor(Math.random() * characters.length)] +
                 (!((i + 1) % 5) ? "-" : "")
             )
             .join("")
             .slice(0, -1);
           // console.log(userkey);
           const data = JSON.stringify({
             // pseudo: userName,
             // email: email,
             // phone: phone,
             password: userkey,
           });
           axios
             .patch(
               process.env.React_App_base_url +
                 `users/updatePassword/` +
                 userId,
               data,
               {
                 headers: {
                   "Access-Control-Allow-Origin": "*",
                   "Access-Control-Allow-Credentials": true,
                   "Content-Type": "application/json",
                   //  Authorization: `Bearer ${isAuthenticated}`,
                 },
               }
             )
             .then((res) => {
               let userObject = "Votre mot de passe temporaire sur WABAKIT";
               let message =
                 "<b>Hello Mme/M. " +
                 userName +
                 "</b><br>" +
                 "Votre mot de passe temporaire WABAKIT est :" +
                 "<b>" +
                 userkey +
                 "</b>" +
                 "<br>" +
                 "N'oubliez pas de le changer à votre prochaine connexion." +
                 "<br>" +
                 "Equipe WABAKIT";

               //  console.log(userEmail, userObject, message);

               sendEmail(userEmail, userObject, message);
               setMsg(
                 "Votre compte a été récupéré avec succès. Nous avons envoyé dans votre boite mail un mot de passe généré  pour vous connecter 👌 ! "
               );
               setTimeout(() => {
                 navigate("../login");
                 setMsg("");
               }, "8000");
             });
           //User Compte ID
         }

         if (!userEmail) {
           setErrormsg(
             "Désolé 🧐, aucun compte n'est enrégistré avec cette addresse E-mail !"
           );
           setTimeout(() => {
             setErrormsg("");
           }, 2000);
         }
       })
       .catch((error) => {
         if (error) {
           setErrormsg(
             "Désolé 🧐, aucun compte n'est enrégistré avec cette addresse E-mail !"
           );
         }
         // console.log("error", error);
         setTimeout(() => {
           setErrormsg("");
         }, 2000);
       });
   }
 }

  return (
    <>
      <Header />
      <div class="relative bg-yellow-500">
        <section
          class="p-2 pt-24 bg-cover bg-center bg-fixed"
          // style="background-image: url('https://picsum.photos/1920/1080')"
        >
          <div class="container mx-auto py-16 mb-48">
            <section class="flex flex-col items-center pt-6 mb-8">
              <div class="w-full bg-white/50 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center md:text-2xl dark:text-white">
                    RECUPERER SON MOT DE PASSE
                  </h1>
                  <div class="space-y-4 md:space-y-6">
                    <p class="text-center p-2 -mb-4 text-red-700">
                      {errormsg}{" "}
                    </p>
                    <div className="text-green-700 p-2">{msg}</div>
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        E-mail
                      </label>
                      <input
                        id="email"
                        name={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        required
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="mano@exemple.com"
                      />
                    </div>

                    <button
                      enable={loading}
                      onClick={() => {
                        validateForm();
                      }}
                      class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <circle cx="12" cy="3" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate0"
                                attributeName="r"
                                begin="0;svgSpinners12DotsScaleRotate2.end-0.5s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate1"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate0.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate2"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate4.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate3"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate1.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate4"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate6.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="21" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate5"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate3.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="3" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate6"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate8.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate7"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate5.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate8"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotatea.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate9"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate7.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotatea"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotateb.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="12" cy="21" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotateb"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate9.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <animateTransform
                              attributeName="transform"
                              dur="6s"
                              repeatCount="indefinite"
                              type="rotate"
                              values="360 12 12;0 12 12"
                            />
                          </g>
                        </svg>
                      ) : (
                        "Valider"
                      )}
                    </button>
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Mot de passe retrouvé ?{" "}
                      <a
                        class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                        href="./login"
                      >
                        Se connecter
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Passwordrecovery;
