import Headadmin from "./Headadmin";
import { useState, useEffet } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import Popup from "../home/Popup";

function Utilisateurs() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);

  const [notifications, setNotifications] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [msg, setMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [solde, setSolde] = useState("");
  const [bonus, setBonus] = useState("");
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [user_Id, setUser_Id] = useState("");

  const [delstatus, setDelstatus] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [dataUsers, setDataUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const navigate = useNavigate();
  // console.log(delstatus);
  // console.log(user_Id);

  function Delete() {
    setMsg(
      <p className="rounded-lg text-center bg-green-200 text-green-700 p-4">
        {t("L'utilisateur a été désactivé avec succèss !")}
      </p>
    );
    setTimeout(() => {
      setMsg("");
    }, 3000);
  }

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/`, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              // console.log(res.data.users);
              const Users = res.data.users;
              setDataUsers(Users);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  //  console.log(dataCommandes);
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 5000);
  }, []);

  //Get user Infos by Id
  useEffect(() => {
    setTimeout(() => {
      if (user_Id !== "") {
        // setIsLoading(true);

        // console.log(user_Id);
        //  setCompteId(compteId);
        axios
          .get(process.env.React_App_base_url + `users/` + user_Id, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            const onlyUser = res.data.data;
            // console.log(onlyUser.isActive);
            setUserName(onlyUser.pseudo);
            setEmail(onlyUser.email);
            setSolde(onlyUser.money);
            setBonus(onlyUser.bonus);

            let sTatusCompte = onlyUser.isActive;

            if (sTatusCompte === true) {
              setStatus("Activé");
            }
            if (sTatusCompte === false) {
              setStatus("Désactivé");
            }
            // setIsLoading(false);
            setShowModal(true);

            // console.log(onlyUser.pseudo);
          });
      }
    }, 1000);
    clearTimeout();
  }, [user_Id]);

  function close() {
    setTimeout(() => {
      setUserId("");
      setShowModal(false);
    }, 1000);
  }

  function UpdateInfo() {
   
    if (delstatus === "") {
      setErrormsg(
        <p className="rounded-lg bg-red-200 text-red-600 text-center p-4">
          {t("Veuillez confirmer le status de ce compte !")}
        </p>
      );
      setTimeout(() => {
        setErrormsg("");
      }, "2000");
    }

    if (delstatus !== "") {
      const ab=[];
      if(delstatus==="1"){
        ab.push(true);
      }
      if (delstatus === "0") {
        ab.push(false);
      }
      const data = JSON.stringify({
        // pseudo: userName,
        // email: email,
        // phone: phone,
        money: solde,
        bonus: bonus,
        isActive: ab[0],
      });
      // console.log(data);
      // return null;
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(process.env.React_App_base_url + `users/` + user_Id, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res);
          setMsg(
            <p className="rounded-lg bg-green-200 text-green-600 text-center p-4">
              {t("Votre profil a été mise à jour avec succès 👌 ! ")}
            </p>
          );
          setTimeout(() => {
            setMsg("");
            setShowModal(false);
            window.location.reload();
          }, "3000");
        });
    }
  }
  return (
    <>
      <Headadmin />

      <div class="py-10">
        {/* <Popup /> */}
        <div class="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
            <nav
              aria-label="Sidebar"
              class="sticky top-4 divide-y divide-gray-300"
            >
              <div class="space-y-1 pb-8">
                <a
                  href="../home/dashadmin"
                  class="bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    class="text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    x-description="Heroicon name: outline/home"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    ></path>
                  </svg>
                  <span class="truncate">Administration</span>
                </a>

                <a
                  href="../home/utilisateurs"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M24 14.6c0 .6-1.2 1-2.6 1.2c-.9-1.7-2.7-3-4.8-3.9c.2-.3.4-.5.6-.8h.8c3.1-.1 6 1.8 6 3.5M6.8 11H6c-3.1 0-6 1.9-6 3.6c0 .6 1.2 1 2.6 1.2c.9-1.7 2.7-3 4.8-3.9zm5.2 1c2.2 0 4-1.8 4-4s-1.8-4-4-4s-4 1.8-4 4s1.8 4 4 4m0 1c-4.1 0-8 2.6-8 5c0 2 8 2 8 2s8 0 8-2c0-2.4-3.9-5-8-5m5.7-3h.3c1.7 0 3-1.3 3-3s-1.3-3-3-3c-.5 0-.9.1-1.3.3c.8 1 1.3 2.3 1.3 3.7c0 .7-.1 1.4-.3 2M6 10h.3C6.1 9.4 6 8.7 6 8c0-1.4.5-2.7 1.3-3.7C6.9 4.1 6.5 4 6 4C4.3 4 3 5.3 3 7s1.3 3 3 3"
                    />
                  </svg>
                  <span class="truncate">Utilisateurs</span>
                </a>

                <a
                  href="../home/depots"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      color="currentColor"
                    >
                      <path d="M20.943 16.835a15.76 15.76 0 0 0-4.476-8.616c-.517-.503-.775-.754-1.346-.986C14.55 7 14.059 7 13.078 7h-2.156c-.981 0-1.472 0-2.043.233c-.57.232-.83.483-1.346.986a15.76 15.76 0 0 0-4.476 8.616C2.57 19.773 5.28 22 8.308 22h7.384c3.029 0 5.74-2.227 5.25-5.165" />
                      <path d="M7.257 4.443c-.207-.3-.506-.708.112-.8c.635-.096 1.294.338 1.94.33c.583-.009.88-.268 1.2-.638C10.845 2.946 11.365 2 12 2s1.155.946 1.491 1.335c.32.37.617.63 1.2.637c.646.01 1.305-.425 1.94-.33c.618.093.319.5.112.8l-.932 1.359c-.4.58-.599.87-1.017 1.035S13.837 7 12.758 7h-1.516c-1.08 0-1.619 0-2.036-.164S8.589 6.38 8.189 5.8zm6.37 8.476c-.216-.799-1.317-1.519-2.638-.98s-1.53 2.272.467 2.457c.904.083 1.492-.097 2.031.412c.54.508.64 1.923-.739 2.304c-1.377.381-2.742-.214-2.89-1.06m1.984-5.06v.761m0 5.476v.764" />
                    </g>
                  </svg>
                  <span class="truncate">Dépôts</span>
                </a>

                <a
                  href="../home/allcommandes"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25q0-.075.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                    />
                  </svg>
                  <span class="truncate">Commandes</span>
                </a>
              </div>
              <div class="pt-10">
                <button
                  onClick={handleLogout}
                  class="flex px-3 text-sm font-medium text-gray-900"
                  id="communities-headline font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22m7-6v-3h-8v-2h8V8l5 4z"
                    />
                  </svg>
                  {t("Déconnexion")}
                </button>
              </div>
            </nav>
          </div>

          <main class="w-full lg:col-span-9 xl:col-span-48 justify-center items-center mx-auto px-8">
            <p class="font-bold text-3xl">
              Listes des
              <span class="text-green-500 mx-1 font-extrabold text-4xl relative inline-block stroke-current">
                utilisateurs
                <svg
                  class="absolute -bottom-0.5 w-full max-h-1.5"
                  viewBox="0 0 55 5"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
                    stroke-width="2"
                  ></path>
                </svg>
              </span>
              de la plateforme
            </p>
            <p className="text-center font-bold mt-8 "> {msg}</p>
            <section class="flex gap-8 py-10 my-auto dark:bg-gray-900 w-2/3">
              <table
                id="example"
                class="min-w-full bg-gray-200 divide-y divide-gray-200"
              >
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nom et Prénom
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Solde
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Bonus
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Rôle
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-gray-300 divide-y divide-gray-200">
                  {dataUsers.map((user) => (
                    <tr key={user.id}>
                      <td class="px-6 py-4 whitespace-nowrap">{user.pseudo}</td>
                      <td class="px-6 py-4 whitespace-nowrap">{user.email}</td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        {user.money} XOF
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">{user.bonus}</td>

                      <td class="px-6 py-4 whitespace-nowrap">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          {user.role}
                        </span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-green-800">
                          {user.createdAt}
                        </span>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() => setUser_Id(user.id)}
                          type="button"
                          class="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          Modifier
                        </button>
                        {/* <button
                          onClick={() => Delete()}
                          type="button"
                          class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                        >
                          Désactiver
                        </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </main>
        </div>
      </div>
      <div className="">
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto w-full">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}

                  {/*body*/}

                  <div
                    id="works"
                    class="relative bg-blue-800 py-10 sm:py-16  lg:py-24"
                  >
                    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <section class="py-10 bg-gray-50 ">
                        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                          <button
                            className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => close()}
                          >
                            ❌
                            {/* <span className=" text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ❌
                    </span> */}
                          </button>
                          <div class="max-w-2xl mt-32 -mb-8 mx-auto text-center">
                            <h2 class="myT text-3xl font-bold mt-16 leading-tight text-black sm:text-4xl lg:text-3xl">
                              INFORMATIONS DE L'UTILISATEUR
                            </h2>
                          </div>
                          <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                            <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                              <button
                                type="button"
                                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                              >
                                <span class="flex text-lg font-semibold text-black">
                                  IDENTITE DE L'UTILISATEUR
                                </span>
                                <svg
                                  id="arrow1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="w-6 h-6 text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                            <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                              <div class="px-4 pb-5 text-gray-700 sm:px-6 sm:pb-6">
                                <div class="p-6 space-y-6">
                                  <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6 sm:col-span-3">
                                      <label class="text-sm font-medium text-gray-900 mb-2">
                                        Nom et Prénom(s)
                                      </label>
                                      <input
                                        value={userName}
                                        class=" bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg   w-full p-2.5"
                                        placeholder="Coman Bolt”"
                                      />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                      <label
                                        for="email"
                                        class="text-sm font-medium text-gray-900  mb-2"
                                      >
                                        Email
                                      </label>
                                      <input
                                        value={email}
                                        class=" bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg   w-full p-2.5"
                                        placeholder="exemple@exemple.com"
                                      />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                      <label class="text-sm font-medium text-gray-900  mb-2">
                                        Solde
                                      </label>
                                      <input
                                        onChange={(e) => {
                                          setSolde(e.target.value);
                                        }}
                                        value={solde}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg   w-full p-2.5"
                                        placeholder="5000"
                                      />
                                    </div>
                                    <div class="col-span-6 sm:col-span-3">
                                      <label
                                        for="price"
                                        class="text-sm font-medium text-gray-900 block mb-2"
                                      >
                                        Bonus
                                      </label>
                                      <input
                                        onChange={(e) => {
                                          setBonus(e.target.value);
                                        }}
                                        value={bonus}
                                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  w-full p-2.5"
                                        placeholder="2300"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p className="text-center mx-24 font-bold mt-8 ">
                                {" "}
                                {errormsg}
                              </p>
                              <p className="text-center mx-24 font-bold mt-8 ">
                                {" "}
                                {msg}
                              </p>
                              <button
                                type="button"
                                class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                              >
                                <span class="flex text-lg font-semibold text-black">
                                  STATUS DU COMPTE
                                </span>
                                <svg
                                  id="arrow2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  class="w-6 h-6 text-gray-400"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                  ></path>
                                </svg>
                              </button>
                              <div
                                id="answer2"
                                class="flex px-4 pb-5 sm:px-6 rounded-lg my-auto  sm:pb-6 text-gray-700"
                              >
                                <select
                                  onClick={(e) => setDelstatus(e.target.value)}
                                  class="block w-sm text-sm font-medium transition duration-75 border border-gray-800 rounded-lg shadow-sm h-9 focus:border-blue-600 focus:ring-1 focus:ring-inset focus:ring-blue-600 bg-none"
                                >
                                  <option value="">Status</option>
                                  <option value="1">Activé</option>
                                  <option value="0">Désactivé</option>
                                </select>
                                <span className="ml-48 p-8 font-bold text-gray-100 rounded-lg bg-yellow-500">
                                  {status}
                                </span>
                              </div>
                            </div>
                            <button
                              class="mt-8 inline-flex items-center justify-center rounded-xl bg-blue-600 py-3 px-6 font-dm text-base font-medium text-white shadow-xl shadow-blue-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                              onClick={() => UpdateInfo()}
                            >
                              Modifier
                            </button>
                          </div>

                          <p class="text-center text-gray-600 textbase mt-9"></p>
                        </div>
                      </section>
                    </div>
                    <div class="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"></div>
                  </div>

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => close()}
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </>
  );
}
export default Utilisateurs;
