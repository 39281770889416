import "./Appdash.css"
import { useState, useEffect } from "react";

export default function Footer(){

    const [showModal, setShowModal] = useState(false);

    setTimeout(() => {setShowModal(true)}, 5000)
    // setTimeout(() => {
    //   setShowModal(false);
    // }, 10000);

    return (
      <>
        <p class="mt-7 text-center text-base">© 2024 WabaKit, Tech</p>
       {showModal ? ( <div class="pub fixed left-5 bottom-5 z-40">
          <a href="https://5sender.com/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="250"
              height="54"
              viewBox="0 0 250 54"
            >
              <g fill="none" fill-rule="evenodd">
                <rect
                  stroke="#221D21"
                  fill="#221D21"
                  x="0.5"
                  y="0.5"
                  width="249"
                  height="53"
                  rx="10"
                ></rect>
                <text
                  font-family="Helvetica-Bold, Helvetica"
                  font-size="9"
                  font-weight="bold"
                  fill="#EEF2FF"
                >
                  <tspan x="53" y="20">
                    Envoyez des SMS anonymes depuis votre PC
                  </tspan>
                </text>
                <text
                  font-family="Helvetica-Bold, Helvetica"
                  font-size="16"
                  font-weight="bold"
                  fill="#EEF2FF"
                >
                  <tspan x="52" y="40">
                    #10 SMS Gratuit ⇾
                  </tspan>
                </text>
                <g transform="translate(17 13)">
                  <path
                    d="M4.33 16.364.328 24.9c-.126.258.007.42.427.34l3.258-.708a.244.244 0 0 1 .317.17l1.467 3.007c.14.324.302.39.428.133l4.26-8.934-6.155-2.544ZM16.67 16.364l4.002 8.536c.133.258-.007.42-.427.34l-3.258-.708a.246.246 0 0 0-.317.17l-1.467 3.007c-.14.324-.295.39-.428.133l-4.26-8.934 6.155-2.544Z"
                    fill="#DE7818"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M9.298 21.392c0 .007-.014 0-.029 0a10.44 10.44 0 0 1-5.97-2.742c-.008-.008-.03-.015-.023-.023l.184-.39c.008-.015.022.022.03.03 1.577 1.466 3.767 2.388 6.007 2.66.008 0 .023 0 .023.008l-.222.457Z"
                    fill="#B35454"
                    fill-rule="nonzero"
                  ></path>
                  <circle
                    fill="#F0CD0A"
                    fill-rule="nonzero"
                    cx="10.5"
                    cy="10.489"
                    r="10.489"
                  ></circle>
                  <circle
                    fill="#D2B309"
                    fill-rule="nonzero"
                    cx="10.5"
                    cy="10.489"
                    r="9.045"
                  ></circle>
                  <circle
                    fill="#E3C101"
                    fill-rule="nonzero"
                    cx="10.75"
                    cy="10.75"
                    r="8.75"
                  ></circle>
                  <path
                    d="M10.45 14.755h2.15V6.3h-2.15L8.3 7.765v1.851l2.04-1.377h.11z"
                    fill="#FFF2B5"
                  ></path>
                  <path
                    d="M12.97 3.089a7.604 7.604 0 0 1 .353 14.698c2.035-1.555 3.384-4.209 3.384-7.224 0-3.184-1.504-5.963-3.738-7.474Z"
                    fill-opacity="0.2"
                    fill="#FFF"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="M11.702 21.392c.007.007.022 0 .029 0 2.293-.288 4.4-1.21 5.986-2.728.007-.007.029-.014.022-.022l-.185-.39c-.007-.015-.022.022-.03.029-1.577 1.467-3.773 2.374-6.021 2.646-.008 0-.023 0-.023.008l.222.457Z"
                    fill="#B35454"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
        </div>) : null}
      </>
    );
}