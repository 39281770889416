import logo from "./logo.svg";
// import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import InscriptionEmail from "./SendEmail";
import { useTranslation } from "react-i18next"; 

function Register() {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState("");
  const [passwordConfirme, setPasswordConfirme] = useState("");
  const [password, setPassword] = useState("");
  const [cgv, setCgv] = useState(false);
  const { t, i18n } = useTranslation();


  const navigate = useNavigate();

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function validateForm() {
    setLoading(true);
    // Perform operation and then set loading to false
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
    // console.log(value); 
    
    // console.log(cgv);

    if (cgv === "" || cgv===false) {
      setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t(
            "Veuillez accepter les conditions générales de ventes et d'utilisation de la plateforme ! *"
          )}
        </p>
      );
        setTimeout(() => {
        setErrormsg("");
      }, 2000);
         
    }

    if (
      pseudo === "" ||
      email === "" ||
      password === "" ||
      passwordConfirme === ""
    ) {
      setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t("Veuillez remplir tous les champs ! *")}
        </p>
      );
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    if (password.length < 8 || password.length > 12) {
      setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t(
            "Attention, votre mot de passe doit être compris entre 8 et 12 caractères y compris des chiffres et des symboles !"
          )}
        </p>
      );
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    // console.log(formData.cgv);

    if (!isValidEmail(email)) {
      setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t("Attention, Votre adresse E-mail est invalide !")}
        </p>
      );
      //console.log(isValidEmail(email));
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    if (password !== passwordConfirme) {
      setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t("Les mots de passe ne sont pas identiques*")}
        </p>
      );
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    var Emailconfirm = isValidEmail(email);

    if (
      pseudo !== "" &&
      email !== "" &&
      password !== "" &&
      passwordConfirme !== "" &&
      password.length >= 8 &&
      Emailconfirm !== false && 
      cgv === true
    ) {
      // setLoading(true);
      // console.log(pseudo, email, value, password, passwordConfirme)
      var ExpireDate = Date.now();

      //  const axios = require("axios");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        pseudo: pseudo,
        email: email,
        password: password,
        role: "membre",
        money: 0,
        bonus: 0,
        notify: "0",
        isActive: true,
      });
      // console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.React_App_base_url + "users", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          let resdata = JSON.parse(result);
          const resp = resdata.statusCode;
          //console.log(resdata);
          //  console.log(resp);
          if (resp === 200) {
            // console.log(sponsorkey);
            let userObject = "Inscription sur WabaKit";
            let message =
              "Hello Mme/M." +
              " " +
              "<b>" +
              pseudo +
              "</b><br><br>" +
              "Nous sommes heureux de vous accueillir dans la grande famille " +
              "<b>WabaKit!</b>" +
              "<br>" +
              " Votre compte a été créé avec succès 👌." +
              "<br>" +
              "Votre mot de passe est: " +
              "<b>" +
              password +
              "</b><br>" +
              " Un numéro virtuel pour être plus discrêt et protéger sa vie privée" +
              "<br>" +
              " Merci de vous connecter, de choisir le pays et le numéro de son choix pour valider WhatsApp, Facebook, ..." +
              "<br><br>" +
              "<a href='https://wabakit.com' target='_blank'>Se connecter &rarr;</a>" +
              "<br><br>" +
              "Equipe WABAKIT";

            InscriptionEmail(email, userObject, message);

            setTimeout(() => {
              setLoading(false);
              navigate("../login");
            }, "1000");
          }

          if (resp === 302) {
            setLoading(false);
            setErrormsg(
              <p className="rounded-lg text-center bg-red-200 p-4">
                {t(
                  "Attention 🧐, un compte est déjà enrégistré avec cette addresse E-mail !"
                )}
              </p>
            );
            setTimeout(() => {
              setErrormsg("");
            }, 2000);
          }
        })
        .catch((error) => {
          
          return{
            error : true,
            msgError : 'Une erreur est survenue lors du traitement de votre demande. Veuillez rééssayer !'
          }
        } );
    }
  }

  return (
    <>
      <Header />
      <div class="relative bg-yellow-500 - p-4">
        <section
          class="bg-cover bg-center bg-fixed "
          // style="background-image: url('https://picsum.photos/1920/1080')"
        >
          <div class="container mx-auto mt-8 py-8 mb-8 ">
            <section class="flex flex-col items-center pt-24 ">
              <div class="w-full bg-white/50 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 -mt-4 sm:p-8">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center md:text-2xl dark:text-white">
                    {t("S'INSCRIRE")}
                  </h1>
                  <div class="space-y-4 md:space-y-6">
                    <p class="text-center p-2 -mb-4 text-red-700">
                      {errormsg}{" "}
                    </p>
                    <div>
                      <label
                        for="name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("Nom et Prénom(s)")}
                      </label>
                      <input
                        id="pseudo"
                        name={pseudo}
                        type="text"
                        autoComplete="pseudo"
                        onChange={(e) => setPseudo(e.target.value)}
                        required
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Emelia Erickson"
                      />
                    </div>
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        E-mail
                      </label>
                      <input
                        id="email"
                        name={email}
                        type="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="mano@exemple.com"
                      />
                    </div>
                    {/* <div class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Numéro WhatsApp
                      </label>

                      <PhoneInput
                        defaultCountry="FR"
                        placeholder="+3301020304"
                        value={value}
                        onChange={setValue}
                      />
                    </div> */}
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("Mot de Passe")}
                      </label>
                      <input
                        id="password"
                        name={password}
                        type="text"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="••••••••"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required=""
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("Confirmez le mot de Passe")}
                      </label>
                      <input
                        id="passwordConfirme"
                        name={passwordConfirme}
                        onChange={(e) => setPasswordConfirme(e.target.value)}
                        type="text"
                        autoComplete="current-password"
                        required
                        placeholder="••••••••"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div class="flex items-center">
                      <input
                        id="cgv"
                        name="cgv"
                        value="true"
                        type="checkbox"
                        onChange={(e) => setCgv(true)}
                        class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                      <label
                        for="remember_me"
                        class="ml-2 block text-sm leading-5 text-gray-900"
                      >
                        {t("J'accepte les conditions Générales de ventes")}
                      </label>
                    </div>
                    <button
                      type="submit"
                      enable={loading}
                      onClick={() => {
                        validateForm();
                      }}
                      class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {" "}
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <circle cx="12" cy="3" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate0"
                                attributeName="r"
                                begin="0;svgSpinners12DotsScaleRotate2.end-0.5s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate1"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate0.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate2"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate4.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate3"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate1.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate4"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate6.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="21" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate5"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate3.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="3" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate6"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate8.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate7"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate5.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate8"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotatea.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate9"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate7.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotatea"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotateb.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="12" cy="21" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotateb"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate9.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <animateTransform
                              attributeName="transform"
                              dur="6s"
                              repeatCount="indefinite"
                              type="rotate"
                              values="360 12 12;0 12 12"
                            />
                          </g>
                        </svg>
                      ) : (
                        ""
                      )}
                      {t("Créer son compte ⇾")}
                    </button>
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      {t("Vous avez déjà un compte ?")}{" "}
                      <a
                        class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                        href="./login"
                      >
                        Se connecter
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Register;
