import Headboard from "./Headboard";
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";


function Update() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [notifications, setNotifications] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [montant, setMontant] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();
const { t, i18n } = useTranslation();


  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setNotifications(userData.notify);
              setUserEmail(userData.email);
              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

   async function submit() {
     setLoading(true);
     if (montant === "") {
       setLoading(false);
       setErrormsg("Veuillez saisir le montant à recharger !");
       setTimeout(() => {
         setMsg("");
         setErrormsg("");
       }, "2000");
     }else if (Number(montant)<5000) {
       setLoading(false);
       setErrormsg("Le montant minimum à recharger est de 5000 FCFA !");
       setTimeout(() => {
         setMsg("");
         setErrormsg("");
       }, "2000");
     }

     if (montant !== "" && Number(montant) >=5000) {
       axios
         .get(process.env.React_App_base_url + `users/` + userID, {
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Access-Control-Allow-Credentials": true,
             "Content-Type": "application/json",
             Authorization: `Bearer ${isAuthenticated}`,
           },
         })
         .then(async (res) => {
           const pseudo = res.data.data.pseudo;
           const email = res.data.data.email;
           const phone = res.data.data.phone;

           if (email) {
             // console.log(expire);
             //  const axios = require("axios");
             let data = JSON.stringify({
               pseudo: pseudo,
               userEmail: email,
               userPhone: "29995959595",
               productname: "WabaKIT",
               montant: Number(montant),
               paymentStatus: "PENDING",
               payRef: "WabaKit-2401-" + Date.now(),
             });

             let config = {
               method: "post",
               maxBodyLength: Infinity,
               url: process.env.React_App_base_url + `payments/visacard`,
               headers: {
                 Authorization: `Bearer ${isAuthenticated}`,
                 "Content-Type": "application/json",
               },
               data: data,
             };

             axios
               .request(config)
               .then((res) => {
                 //  console.log(JSON.stringify(response.data));
                //  console.log(res.data);
                 let respData = res.data.message;
                 let PaymentUrl = res.data.paymentRediref.url;
                 let Payment_Id = res.data.paymentId;
                 let Payment_Ref = res.data.paymentRef;
                 let price = res.data.montant;
                 // const NewData = JSON.parse(res.config.data);
                 if (respData) {
                   setMsg(respData);
                   setLoading(true);
                   console.log(price);

                   let commandeID = Payment_Id;
                   localStorage.setItem("odr", commandeID);
                   localStorage.setItem("money", price);
                   setTimeout(() => {
                     window.location.href = PaymentUrl;
                   }, 3000);
                   // let commandePrice = price;
                   // localStorage.setItem("theprice", commandePrice);
                   // console.log(PaymentUrl);
                 }
                 if (!email) {
                   setErrormsg(
                     "*Erreur de connexion. Patientez et réessayer le paiement !"
                   );
                   setTimeout(() => {
                     setErrormsg("");
                   }, 3000);
                   //navigate("./dashboard/updateplan");
                   return null;
                 }
               })
               .catch((error) => {
                 //  console.log(error);
                 if (error) {
                   setLoading(false);
                   setErrormsg(
                     "*Erreur de connexion. Patientez et réessayer le paiement !"
                   );
                   setTimeout(() => {
                     setErrormsg("");
                   }, 3000);
                   //navigate("./dashboard/updateplan");
                   return null;
                 }
               });
           }
         });
     }
   }

  return (
    <>
      <Headboard />
      <div class="py-10">
        <div class="mx-auto max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-8 lg:px-8">
          <div class="hidden lg:col-span-3 lg:block xl:col-span-2">
            <nav
              aria-label="Sidebar"
              class="sticky top-4 divide-y divide-gray-300"
            >
              <div class="space-y-1 pb-8 ">
                <a
                  href="./dashboard"
                  class="bg-gray-200 text-gray-900 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                >
                  <svg
                    class="text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    x-description="Heroicon name: outline/home"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    ></path>
                  </svg>
                  <span class="truncate">{t("Tableau de bord")} </span>
                </a>

                <a
                  href="./profil"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="currentColor"
                      d="M19 13h7v2h-7zm0-5h11v2H19zm0-5h11v2H19zm-8 27H7a2.006 2.006 0 0 1-2-2v-7a2.006 2.006 0 0 1-2-2v-6a2.947 2.947 0 0 1 3-3h6a2.947 2.947 0 0 1 3 3v6a2.006 2.006 0 0 1-2 2v7a2.006 2.006 0 0 1-2 2M6 12a.945.945 0 0 0-1 1v6h2v9h4v-9h2v-6a.945.945 0 0 0-1-1zm3-3a4 4 0 1 1 4-4a4.012 4.012 0 0 1-4 4m0-6a2 2 0 1 0 2 2a2.006 2.006 0 0 0-2-2"
                    />
                  </svg>
                  <span class="truncate">{t("Profil")} </span>
                </a>

                {/* <a
                  href="./enumber"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M0 64v384c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64V128L256 0H64C28.7 0 0 28.7 0 64m224 192h-64v-64h64zm96 0h-64v-64h32c17.7 0 32 14.3 32 32zm-64 128h64v32c0 17.7-14.3 32-32 32h-32zm-96 0h64v64h-64zm-96 0h64v64H96c-17.7 0-32-14.3-32-32zm0-96h256v64H64zm0-64c0-17.7 14.3-32 32-32h32v64H64z"
                    />
                  </svg>
                  <span class="truncate">{t("E-numéro SMS")} </span>
                </a> */}

                <a
                  href="./recharges"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m17.371 18.587l-.656-.656q-.128-.13-.306-.13t-.307.13t-.129.304t.129.303l.86.86q.186.187.419.187t.419-.187l2.098-2.067q.129-.13.139-.297t-.139-.317q-.129-.129-.316-.129t-.317.13zm-.64-9.856q.213 0 .356-.143t.144-.357t-.144-.357t-.356-.143H7.269q-.213 0-.356.143t-.144.357t.144.357t.356.143zM18 22.115q-1.671 0-2.836-1.164T14 18.115t1.164-2.835T18 14.115t2.836 1.165T22 18.115t-1.164 2.836T18 22.115M5.615 4h12.77q.67 0 1.143.472q.472.472.472 1.143v5.945q-.892-.293-1.828-.301t-1.845.241H7.269q-.213 0-.356.143T6.769 12t.144.357t.356.143h6.812q-.752.521-1.326 1.223t-.945 1.546H7.27q-.214 0-.357.144t-.144.356t.144.357t.356.143h4.21q-.108.404-.168.815t-.061.858q0 .685.143 1.359q.144.674.43 1.299q-.029.006-.06-.009t-.055-.037l-.82-.57q-.111-.072-.234-.072t-.235.073l-.877.607q-.111.073-.234.073t-.235-.073l-.877-.607q-.111-.073-.234-.073t-.235.073l-.877.607q-.112.073-.235.073t-.234-.073l-.877-.607q-.112-.073-.235-.073t-.234.073l-.781.607q-.058.039-.254.13V5.614q0-.67.472-1.143Q4.944 4 5.615 4"
                    />
                  </svg>
                  <span class="truncate">{t("Mes Recharges")} </span>
                </a>

                <a
                  href="./commandes"
                  class="text-gray-700 hover:bg-gray-50 group flex items-center px-3 py-2 text-sm font-medium rounded-md"
                  x-state-description='undefined: "bg-gray-200 text-gray-900", undefined: "text-gray-700 hover:bg-gray-50"'
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m24.822 32.166l-9.593 5.168l-9.592-5.168V42.5h19.185zm-5.28-3.891l5.28 3.891m-19.185 0l9.592-7.069l2.467 1.817m4.796-15.658h15.349M22.492 15.81h15.349m-15.349 4.555h9.73M39.767 5.5H20.292a2.596 2.596 0 0 0-2.596 2.596V30.12l4.065-4.065h18.006a2.596 2.596 0 0 0 2.596-2.596h0V8.096A2.596 2.596 0 0 0 39.767 5.5"
                    />
                  </svg>
                  <span class="truncate">{t("Historiques")} </span>
                </a>
              </div>
              <div class="pt-10">
                <button
                  onClick={handleLogout}
                  class="flex px-3 text-sm font-medium text-gray-900"
                  id="communities-headline font-bold"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22m7-6v-3h-8v-2h8V8l5 4z"
                    />
                  </svg>
                  {t("Déconnexion")}
                </button>
              </div>
            </nav>
          </div>

          <main class="w-full lg:col-span-9 xl:col-span-48 justify-center items-center mx-auto px-8">
            {/* <p class="font-bold text-3xl mx-auto text-center">
              Rechargez votre
              <span class="text-green-500 mx-1 font-extrabold text-4xl relative inline-block stroke-current">
                comptes via
                <svg
                  class="absolute -bottom-0.5 w-full max-h-1.5"
                  viewBox="0 0 55 5"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0.652466 4.00002C15.8925 2.66668 48.0351 0.400018 54.6853 2.00002"
                    stroke-width="2"
                  ></path>
                </svg>
              </span>
              ....MTN BENIN, MOOV BENIN, VISACARD, TMONEY TOGO, FLOOZ TOGO
            </p> */}
            <section class="flex gap-8 -mt-8 py-10 my-auto dark:bg-gray-900">
              <div class="max-w-xl h-48 mx-auto mt-2 flex w-full flex-col border rounded-lg bg-yellow-500 p-8">
                <div className="rounded-lg bg-[url('https://internationaldirector.com/wp-content/uploads/2018/09/New-Tech.jpg')] bg-cover bg-center bg-no-repeat items-center">
                  <h2 class="title-font mb-1 p-4 text-center text-lg font-bold text-xl text-gray-100 ">
                    AJOUTER UN MONTANT A VOTRE COMPTE XOF
                  </h2>
                  <p class="mb-5 leading-relaxed text-yellow-300 p-4">
                    Vous serez rediriger vers la page de paiement. Si vous
                    finissez avec succes le paiement, votre compte sera mise à
                    jour automatiquement
                  </p>
                </div>

                <div class="mb-4">
                  <label
                    for="number"
                    class="text-sm leading-7 text-center font-bold text-gray-600"
                  >
                    Mettez le Montant à recharger dans le champs ci-dessous:
                  </label>
                  <input
                    type="number"
                    id="montant"
                    name="montant"
                    onChange={(e) => setMontant(e.target.value)}
                    placeholder="5000"
                    required
                    class="w-full rounded border border-gray-300 bg-white py-1 px-3 text-base leading-8 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                  />
                </div>
                <div class="mb-4">
                  <label for="message" class="text-sm leading-7 text-gray-600">
                    {/* Message */}
                  </label>
                  <textarea
                    disabled
                    id="message"
                    name="message"
                    class="bg-[url('https://img.freepik.com/premium-vector/online-web-internet-digital-money-transaction-by-terminal-smartphone-illustration_133260-1107.jpg?size=626&ext=jpg')] bg-cover bg-center bg-no-repeat h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
                  ></textarea>
                </div>
                <button
                  onClick={submit}
                  enable={loading}
                  class="rounded border-0 bg-indigo-500 py-2 px-6 text-lg text-white hover:bg-indigo-600 focus:outline-none"
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 animate-spin mx-auto"
                      viewBox="0 0 24 24"
                    >
                      <g>
                        <circle cx="12" cy="3" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate0"
                            attributeName="r"
                            begin="0;svgSpinners12DotsScaleRotate2.end-0.5s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="16.5" cy="4.21" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate1"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate0.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="7.5" cy="4.21" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate2"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate4.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="19.79" cy="7.5" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate3"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate1.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="4.21" cy="7.5" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate4"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate6.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="21" cy="12" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate5"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate3.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="3" cy="12" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate6"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate8.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="19.79" cy="16.5" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate7"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate5.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="4.21" cy="16.5" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate8"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotatea.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="16.5" cy="19.79" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotate9"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate7.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="7.5" cy="19.79" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotatea"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotateb.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <circle cx="12" cy="21" r="1" fill="currentColor">
                          <animate
                            id="svgSpinners12DotsScaleRotateb"
                            attributeName="r"
                            begin="svgSpinners12DotsScaleRotate9.begin+0.1s"
                            calcMode="spline"
                            dur="0.6s"
                            keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                            values="1;2;1"
                          />
                        </circle>
                        <animateTransform
                          attributeName="transform"
                          dur="6s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="360 12 12;0 12 12"
                        />
                      </g>
                    </svg>
                  ) : (
                    "Continuer ⇾"
                  )}
                </button>
                <p className="text-bleu-500 p-2 text-xl text-center">{msg}</p>
                <p class="text-center p-2 text-2xl text-orange-400">
                  {errormsg}{" "}
                </p>
                <p class="mt-3 text-xs text-yellow-600">
                  *NB: Le montant minimum à recharger est 5000FCFA
                </p>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
export default Update;
