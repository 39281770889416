// App.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line, Bar } from "react-chartjs-2";
import "chart.js/auto";

const Charts = () => {
  const [chartData, setChartData] = useState([]);
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      axios
        .get(process.env.React_App_base_url + `getnumbers/`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res.data.Numbers);
          const Vente = res.data.Numbers;
          // Filter exemple
          //           var now = new Date();
          // let yesterday = new Date(
          //   new Date().getTime() - 30 * 24 * 60 * 60 * 1000
          // );
          // let last7Days = new Date(
          //   new Date().getTime() - 1 * 24 * 60 * 60 * 1000
          // );
          //   var pastDate = new Date(new Date().setDate(new Date().getDate() - 2));
          //   var data = Vente.filter((p) => p["createAt"] ="2024-07-08T22:17:05.000Z");
          // console.log(pastDate, data);

          // let filtered = Vente.filter((product) => {
          //   let productDate = new Date(product["createdAt"]);
          //   // console.log(productDate);
          //   return (
          //     productDate <= last7Days
          //     // &&
          //     // productDate <= date.selection.endDate
          //   );
          // });
          // console.log(last7Days, filtered);
            
                   const monthNames = [
                     "Janvier",
                     "Février",
                     "Mars",
                     "Avril",
                     "Mai",
                     "Juin",
                     "Juillet",
                     "Août",
                     "Septembre",
                     "Octobre",
                     "Novembre",
                     "Décembre",
                   ];

                   const monthlyData = {};

                   Vente.forEach((item) => {
                     const date = new Date(item["createdAt"]);
                     const month = date.getMonth(); // 0-11
                     const year = date.getFullYear();
                     const key = `${year}-${month}`;

                     if (!monthlyData[key]) {
                       monthlyData[key] = 0;
                     }
                     monthlyData[key] += 1;
                   });

                   const labels = [];
                   const values = [];

                   Object.keys(monthlyData)
                     .sort()
                     .forEach((key) => {
                       const [year, month] = key.split("-");
                       labels.push(`${monthNames[parseInt(month)]} ${year}`);
                       values.push(monthlyData[key]);
                     });

          // const values = Vente.map((item) => item["numero"]);

          const Service = Vente.map((item) => item["service"]);
          const Numprice = Vente.map((item) => item["price"]);
          const number = Vente.map((item) => item["numero"]);
          // const DateCreate = Vente.map(
          //   (item) => item["createdAt"].substring(0, 10).replace(/-/g, "/")

          //   //  new Date(item["createAt"]) <= yesterday
          // );
          // console.log(DateCreate);
          setChartData({
            labels: labels,
            datasets: [
              {
                label: "Ventes",
                type: "bar",
                data: values,
                lineTension: 0.3,
                backgroundColor: "rgba(0, 97, 242, 1)",
                borderColor: "rgba(0, 97, 242, 1)",
                pointRadius: 3,
                pointBackgroundColor: "rgba(0, 97, 242, 1)",
                pointBorderColor: "rgba(0, 97, 242, 1)",
                pointHoverRadius: 3,
                pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
                pointHoverBorderColor: "rgba(0, 97, 242, 1)",
                pointHitRadius: 10,
                pointBorderWidth: 2,
              },
              // {
              //   label: "Service",
              //   data: Service,
              //   type: "bar",
              //   backgroundColor: "rgba(223,20,255,200)",
              //   borderColor: "rgba(223,102,255,1)",
              //   borderWidth: 1,
              // },
              // {
              //   label: "Achat le plus élévé",
              //   data: Numprice,
              //   type: "bar",
              //   backgroundColor: "rgba(160,102,255,0.6)",
              //   borderColor: "rgba(160,102,255,2)",
              //   borderWidth: 1,
              // },
              // {
              //   label: "Numéro",
              //   data:number,
              //   // type: "bar",
              //   backgroundColor: "rgba(160,102,255,0.6)",
              //   borderColor: "rgba(160,102,255,2)",
              //   borderWidth: 1,
              // },
            ],
          });
          setLoading(false);
          // console.log(labels, values);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }, []);
  return <div>{loading ? <p>Loading...</p> : <Line data={chartData} />}</div>;
};

export default Charts;
