import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import Accueil from './Accueil';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Register from './Register';
import reportWebVitals from './reportWebVitals';
import Login from './Login';
import Recoverypassword from "./Recoverypassword";
import Dashboard from './home/Dashboard';
import Profil from './home/Profil';
import Enumber from './home/Enumber';
import Commandes from './home/Commandes';
import Recharges from "./home/Recharges";
import Update from './home/Update';
import Getcode from './home/Getcode';
import Cookies from './Cookies';
import Confirmation from './Confirmation';
import Endpoint from './home/Endpoint';
import Dashadmin from './home/Dashadmin';
import Utilisateurs from './home/Utilisateurs';
import AllCommandes from './home/AllCommandes';
import Depots from './home/Depots';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router>
        <div>
          <section>
            <Routes>
              {" "}
              <Route path="/" element={<Accueil />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Recoverypassword" element={<Recoverypassword />} />
              <Route path="/home/Dashboard" element={<Dashboard />} />
              <Route path="/home/Enumber" element={<Enumber />} />
              <Route path="/home/Profil" element={<Profil />} />
              <Route path="/home/Recharges" element={<Recharges />} />
              <Route path="/home/Commandes" element={<Commandes />} />
              <Route path="/home/Update" element={<Update />} />
              <Route path="/home/Getcode" element={<Getcode />} />
              <Route path="/home/Dashadmin" element={<Dashadmin />} />
              <Route path="/Cookies" element={<Cookies />} />
              <Route path="/Confirmation" element={<Confirmation />} />
              <Route path="/home/Endpoint" element={<Endpoint />} />{" "}
              <Route path="/home/Utilisateurs" element={<Utilisateurs />} />{" "}
              <Route path="/home/AllCommandes" element={<AllCommandes />} />{" "}
              <Route path="/home/Depots" element={<Depots />} />{" "}

            </Routes>
          </section>
        </div>
      </Router>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();