import logo from "./logo.svg";
// import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";

function Confirmation() {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Header />
      <div class="relative bg-yellow-500">
        <section
          class="p-2 pt-24 bg-cover bg-center bg-fixed"
          // style="background-image: url('https://picsum.photos/1920/1080')"
        >
          <div class="container mx-auto py-16 mb-4 -mt-32">
            <body class="bg-yellow-500 flex -mb-24 flex-col items-center justify-center h-screen w-full dark:bg-gray-900">
              <div class="w-full max-w-md px-8 py-10  bg-white/50 rounded-lg shadow-md dark:bg-gray-950 dark:text-gray-200">
                <h1 class="text-2xl font-semibold text-center mb-6">
                  Enter le code de validation de votre compte OTP
                </h1>
                <p class="text-gray-600 text-center mb-4">
                  Saisissez le code que nous venons de vous envoyer à l'adresse
                  E-mail...Veuillez verifier aussi votre spam ✋
                </p>
                <div class="grid grid-cols-5 gap-x-4 my-2">
                  <div
                    contenteditable="true"
                    class="rounded-lg bg-gray-100 cursor-text dark:bg-gray-800 w-14 aspect-square flex items-center justify-center"
                  >
                    <span class="text-gray-700 dark:text-gray-400">
                      <input
                        className="text-center bg-transparent outline-none"
                        type="text"
                        value="1"
                      />
                    </span>
                  </div>
                  <div
                    contenteditable="true"
                    class="rounded-lg bg-gray-100 cursor-text dark:bg-gray-800 w-14 aspect-square flex items-center justify-center"
                  >
                    <span class="text-gray-700 dark:text-gray-400">
                      <input
                        className="text-center bg-transparent outline-none"
                        type="text"
                        value="9"
                      />
                    </span>
                  </div>
                  <div
                    contenteditable="true"
                    class="rounded-lg bg-gray-100 cursor-text dark:bg-gray-800 w-14 aspect-square flex items-center justify-center"
                  >
                    <span class="text-gray-700 dark:text-gray-400">
                      <input
                        className="text-center bg-transparent outline-none"
                        type="text"
                        value="5"
                      />
                    </span>
                  </div>
                  <div
                    contenteditable="true"
                    class="rounded-lg bg-gray-100 cursor-text dark:bg-gray-800 w-14 aspect-square flex items-center justify-center"
                  >
                    <span class="text-gray-700 dark:text-gray-400">
                      <input
                        className="text-center bg-transparent outline-none"
                        type="text"
                        value="2"
                      />
                    </span>
                  </div>
                  <div
                    contenteditable="true"
                    class="rounded-lg bg-gray-100 cursor-text dark:bg-gray-800 w-14 aspect-square flex items-center justify-center"
                  >
                    <span class="text-gray-700 dark:text-gray-400 outline-none">
                      <input
                        className="text-center bg-transparent outline-none"
                        type="text"
                        value="8"
                      />
                    </span>
                  </div>
                </div>
                <div class="flex items-center flex-col justify-between mb-6">
                  <p class="text-gray-600 text-sm text-justify">
                    Veuillez patienter pour recevoir le code si votre E-mail est
                    correcte. Si votre E-mail n'est pas correcte, veuillez nous
                    contacter via le chat !
                  </p>
                  {/* <div class="flex items-center space-x-2">
                    <button class="px-3 py-2 text-sm font-medium text-center rounded text-gray-500 hover:text-blue-500">
                      Request via Call
                    </button>
                    <button class="px-3 py-2 text-sm font-medium text-center rounded text-gray-500 hover:text-blue-500">
                      Request Again (00:00:36)
                    </button>
                  </div> */}
                </div>
                <button class="w-full px-4 py-2 text-lg font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                  Confirmer ⇾
                </button>
              </div>
            </body>
          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Confirmation;
