import logo from "./logo.svg";
// import './App.css';
import Header from "./Header";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Login() {
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t, i18n } = useTranslation();


  function togglePasswordVisibility() {
    setIsPasswordVisible((password) => !password);
  }

  
  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function validateForm() {
    setLoading(true);
    // Perform operation and then set loading to false
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);

    if (email === "" || password === "") {
       setLoading(false);
      setErrormsg(
        
        <p className="rounded-lg text-center bg-red-200 p-4">
                  {t("Veuillez remplir tous les champs ! *")}
        </p>      
        );
      setTimeout(() => {
        setErrormsg("");
      }, 3000);
    }

    if (!isValidEmail(email)) {
       setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t("Attention, Votre adresse E-mail est invalide !")}{" "}
        </p>
      );
      //console.log(isValidEmail(email));
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    if (password === "") {
       setLoading(false);
      setErrormsg(
        <p className="rounded-lg text-center bg-red-200 p-4">
          {t("Veuillez mettre votre mot de passe *")}{" "}
        </p>
      );
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    var Emailconfirm = isValidEmail(email);

    if ((email !== "" && password !== "") || Emailconfirm !== false) {
      // const axios = require("axios");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: email,
        password: password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.React_App_base_url + "auth/login", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          let resdata = JSON.parse(result);
          let userName = resdata["userinfos"].pseudo;
          let userId = resdata["userinfos"].id;
          let role = resdata["userinfos"].role;
          let resp = resdata["access_token"];
          let userActive = resdata["userinfos"].isActive;

          //console.log((resp));

          if (resp && userActive === true && role === "membre") {
            localStorage.setItem("uuid", resp);
            let isLoginID = btoa(userId);
            localStorage.setItem("isLoggedIn", isLoginID);
            let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

            let encodeTokenStatic = btoa(TokenStatic);
            localStorage.setItem("reactjs", encodeTokenStatic);

            // navigate("../home/dashboard");
            window.location.href = "./home/dashboard";
          }
          if (resp && userActive === true && role === "admin") {
            localStorage.setItem("uuid", resp);
            let isLoginID = btoa(userId);
            localStorage.setItem("isLoggedIn", isLoginID);
            let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

            let encodeTokenStatic = btoa(TokenStatic);
            localStorage.setItem("reactjs", encodeTokenStatic);

            // navigate("../home/dashadmin", {
            //   state: {
                
            //     userName: userName,
            //     userId: userId,
            //   },
            // });
            // navigate("../home/dashadmin");
            window.location.href = "./home/dashadmin";

          }
          if (resp && userActive === false) {
             setLoading(false);
            setErrormsg(
              <p className="rounded-lg text-center bg-red-200 p-4">
                {t(
                  "Désolé 👽, votre compte est bloqué. Veuillez contacter le service technique au contact@wabakit.com !"
                )}{" "}
              </p>
            );
            setTimeout(() => {
              setErrormsg("");
            }, 2000);
          }
          if (!resp) {
             setLoading(false);
            setErrormsg(
              <p className="rounded-lg text-center bg-red-200 p-4">
                {t(
                  "Attention 🧐, aucun compte n'est enrégistré avec cette addresse E-mail !"
                )}{" "}
              </p>
            );
            setTimeout(() => {
              setErrormsg("");
            }, 2000);
          }
        })
        .catch((error) => {
           setLoading(false);
          if (error) {
            
            setErrormsg(<p className="rounded-lg text-center bg-red-200 p-4">
              {t("Désolé, vos identifiants ne sont pas corrects 👀 !!")}{" "}
            </p>);
          }
          // console.log("error", error);
        });
    }
  }

  return (
    <>
      <Header />
      <div class="relative bg-yellow-500  p-4">
        <section
          class="bg-cover bg-center bg-fixed mb-24"
          // style="background-image: url('https://picsum.photos/1920/1080')"
        >
          <div class="container mx-auto py-16  ">
            <section class="flex flex-col items-center pt-24 ">
              <div class="w-full bg-white/50 rounded-lg shadow  dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center md:text-2xl dark:text-white">
                    {t("SE CONNECTER")}
                  </h1>
                  <div class="space-y-4 md:space-y-6">
                    <p class="text-center p-2 -mb-4 text-red-700">
                      {errormsg}{" "}
                    </p>
                    <div>
                      <label
                        for="email"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        E-mail
                      </label>
                      <input
                        id="email"
                        name={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        required
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="mano@exemple.com"
                      />
                    </div>
                    <div>
                      <label
                        for="password"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {t("Mot de Passe")}
                      </label>
                      <span class="absolute  mt-3 ml-80 cursor-pointer">
                        <img
                          onClick={togglePasswordVisibility}
                          src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sign_in-svg5.svg"
                          alt="viewport"
                        />
                      </span>
                      <input
                        id="password"
                        name={password}
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                        required
                        placeholder="••••••••"
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                    <div class="text-sm leading-5">
                      <a
                        href="./recoverypassword"
                        class="font-medium text-blue-500 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                      >
                        {t("Mot de passe oublié ?")}
                      </a>
                    </div>
                    <button
                      enable={loading}
                      onClick={() => {
                        validateForm();
                      }}
                      class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 animate-spin mx-auto"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <circle cx="12" cy="3" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate0"
                                attributeName="r"
                                begin="0;svgSpinners12DotsScaleRotate2.end-0.5s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate1"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate0.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="4.21"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate2"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate4.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate3"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate1.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="7.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate4"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate6.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="21" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate5"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate3.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="3" cy="12" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotate6"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate8.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="19.79"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate7"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate5.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="4.21"
                              cy="16.5"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate8"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotatea.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="16.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotate9"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate7.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle
                              cx="7.5"
                              cy="19.79"
                              r="1"
                              fill="currentColor"
                            >
                              <animate
                                id="svgSpinners12DotsScaleRotatea"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotateb.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <circle cx="12" cy="21" r="1" fill="currentColor">
                              <animate
                                id="svgSpinners12DotsScaleRotateb"
                                attributeName="r"
                                begin="svgSpinners12DotsScaleRotate9.begin+0.1s"
                                calcMode="spline"
                                dur="0.6s"
                                keySplines=".27,.42,.37,.99;.53,0,.61,.73"
                                values="1;2;1"
                              />
                            </circle>
                            <animateTransform
                              attributeName="transform"
                              dur="6s"
                              repeatCount="indefinite"
                              type="rotate"
                              values="360 12 12;0 12 12"
                            />
                          </g>
                        </svg>
                      ) : (
                        ""
                      )}
                      {t("Valider")}
                    </button>
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      {t("Vous n'avez pas un compte ?")}{" "}
                      <a
                        class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                        href="./register"
                      >
                        {t("S'incrire")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Login;
