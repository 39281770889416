import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [showCooky, setShowCooky] = useState();
  const { t, i18n } = useTranslation();


  // Show the cookie policy modal when the page loads.
  useEffect(() => {
    setTimeout(() => {
      function checkCookie() {
        let checkCoo = localStorage.getItem("Cookies");
        if (checkCoo === "accepted") {
          setShowCooky(false);
        }
        if (checkCoo !== "accepted") {
          setShowCooky(true);
        }
      }

      checkCookie();
    }, 1000);
  });

  function Cookies() {
    localStorage.setItem("Cookies", "accepted");
    // console.log("Yes");
    setShowCooky(false);
  }

  return (
    <footer class="mt-20 xl:mt-32 mx-auto w-full relative text-center bg-blue-600 text-white">
      <div class="px-6 py-8 md:py-14 xl:pt-20 xl:pb-12">
        <h2 class="font-bold text-3xl xl:text-4xl leading-snug">
          {t("Créez simplement votre numéro virtuel en quelques clics")}
        </h2>
        <a
          class="mt-8 xl:mt-12 px-12 py-5 text-lg font-medium leading-tight inline-block bg-blue-800 rounded-full shadow-xl border border-transparent hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-sky-999 focus:ring-sky-500"
          href="./register"
        >
          {t("Démarrer ⇾")}
        </a>
        <div class="mt-14 xl:mt-20">
          <nav class="flex flex-wrap justify-center text-lg font-medium">
            <div class="px-5 py-2">
              <button onClick={() => setShowModal(true)}>
                {t("Conditions Générales de ventes")} ⇾
              </button>
            </div>
          </nav>
          <p class="mt-7 text-base">© 2024 WabaKit</p>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center text-gray-500 text-justify flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative mt-48  w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 mt-48 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start mt-64 justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl mx-auto font-semibold">
                    {t("Conditions Générales de Vente (CGV)")}
                  </h3>
                  <button
                    className="p-1 ml-auto border-0 text-black opacity-16 my-auto rounded-full float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-blue-200 rounded-lg  text-black  h-8 w-8  text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative -mt-8 p-6 flex-auto">
                  <div class="">
                    <div class="container mx-auto px-4 py-8">
                      <p class="mb-4 text-justify">
                        {t(
                          "En acceptant les présentes Conditions Générales de Ventes (CGV) , vous déclarez que vous avez au moins l'âge de la majorité dans votre État ou province de résidence. Vous ne pouvez pas utiliser nos produits à des fins illégales ou non autorisées et vous ne pouvez pas, dans le cadre de l'utilisation du service, violer les lois de votre juridiction (y compris, mais sans s'y limiter, les lois sur les droits d'auteur)."
                        )}
                      </p>
                      <h2 class="text-2xl font-bold mb-2">
                        {t("I- Mentions légales")}
                      </h2>

                      <p class="mb-4">
                        {t("Notre site web est la propriété de WABAKIT. Les numéros attribués sont à usage professionnel pour vous aider à séparer votre vie privée de la vie professionnelle et ansi protéger et sécurisé vos données personnelles en ligne. Ils vous aident à configurer des applications et sites en lignes. Après 15 min, le numéro n'est plus disponible mais le service pour lequel vous l'avez utilisé est toujours disponible et vous vous pouvez mettre à jour vos informations.")}
                      </p>

                      <h2 class="text-2xl font-bold mb-2">
                        {t("II- Qui peut utiliser nos services ?")}
                      </h2>

                      <p class="mb-4">
                        {t(
                          "Si vous acceptez les présentes Conditions et utilisez nos Services à titre personnel ou au nom d'une entreprise ou organisation, vous déclarez et garantissez que vous êtes majeur et autorisé à le faire."
                        )}
                      </p>

                      <h2 class="text-2xl font-bold mb-2">
                        {t("III- Déclaration de garantie")}
                      </h2>

                      <p class="mb-4 text-justify">
                        {t(
                          "Wabakit s'assurera toujours que la plateforme soit disponible sauf en cas de panne technique de son fournisseur d'hébergement ou en cas d'incident indépendemment de sa volonté."
                        )}
                      </p>

                      <p class="mb-4 text-justify">
                        {t(
                          "Sans préjudice des autres droits de Wabakit en vertu des présentes Conditions, si vous ne respectez pas ces conditions, pourra prendre les mesures qu'il jugera appropriées pour remédier à la violation, notamment en suspendant votre accès à la plateforme, en vous interdisant l'accès au site Web, en bloquant les ordinateurs utilisant votre adresse IP pour accéder au site Web, en contactant votre fournisseur de services Internet pour lui demander de bloquer votre accès au site Web et / ou en entamant des poursuites judiciaires à votre encontre."
                        )}
                      </p>

                      <h2 class="text-2xl font-bold mb-2">
                        {t("IV- Restrictions")}{" "}
                      </h2>

                      <p class="mb-4 text-justify">
                        {t(
                          "Vous êtes responsable de votre numéro virtuel et de son usage . En cas de plainte, vous êtes tenu responsable des préjudices. Nous vous rappelons que notre service est une solutions pour vous permetre de vous connecter au monde des affaires ici et d'ailleurs"
                        )}
                      </p>

                      <h2 class="text-2xl font-bold mb-2">
                        {t("V- Validité du présent CGV")}
                      </h2>

                      <p class="mb-4 text-justify">
                        {t(
                          "Le présent CGV est valable pour une durée indéterminée. Le début d’utilisation des services de la plateforme et des services y afférents marque l’application du contrat à l’égard de l’Utilisateur:"
                        )}
                      </p>

                      <ul class="list-disc list-inside mb-4">
                        {/* <li>
                          {t(
                            "Les présentes conditions doivent être interprétées, exécutées et régies conformément aux lois de l'espace OHADA. Aussi, vous vous soumettez aux juridictions compétentes de ces pays et celles de la France pour la résolution de tout litige."
                          )}
                        </li> */}
                        {/* <li>
                          En cas de litige nous seront ouvert au négociation à
                          l'amiable avant t'entamer les procédures judiciaires
                        </li> */}
                        <li>
                          
                          {t("Tout copie et reproduction de CGV est strictement interdit")}
                        </li>
                        <li>
                          {t("Merci de faire recours à l'administration de WabaKit en cas d'incompréhension du présent contrat")}
                        </li>
                      </ul>

                      <p class="mb-4">
                        {t("Ce présent Coditions Générales de Ventes (CGV) peut être modifiée sans préavis.")}
                      </p>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    {t("Fermer")}
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {/* Cookies */}
      {showCooky ? (
        <div class="fixed bottom-0 p-6 w-full flex justify-center z-[2000]">
          <div class="bg-white bg-opacity-95 text-xs text-gray-600 rounded-md fade w-[450px] show">
            <div class="p-4 flex items-center justify-between px-6 rounded border border-green-700">
              <p>                
                {t("En continuant de naviguer sur ce site, vous acceptez les")}
                <a
                  class="underline font-bold ml-2"
                  href="./cookies"
                  target="_blank"
                >
                  Cookies Policy.
                </a>
              </p>
              <button
                type="button"
                onClick={() => Cookies()}
                class="px-5 py-3 rounded-lg text-white bg-green-700 hover:bg-green-800 ml-1.5 min-w-max"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </footer>
  );
}
export default Footer;
