import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [seconds, setSeconds] = useState(900);
  

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [seconds]);

  return (
    <div>
      <h1 className='text-yellow-600'>Expire dans : {seconds} s</h1>
    </div>
  );
};

export default CountdownTimer;




// import React, { useState, useEffect } from "react";

// const CountdownTimer = () => {
//   const initialTime = 900; // Duration of the countdown in seconds

//   const getTimeRemaining = () => {
//     const savedTime = localStorage.getItem("countdownTime");
//     const savedTimestamp = localStorage.getItem("countdownTimestamp");

//     if (savedTime && savedTimestamp) {
//       const now = Date.now();
//       const elapsed = Math.floor((now - parseInt(savedTimestamp, 10)) / 1000);
//       const remaining = parseInt(savedTime, 10) - elapsed;
//       return remaining > 0 ? remaining : 0;
//     }
//     return initialTime;
//   };

//   const [seconds, setSeconds] = useState(getTimeRemaining);

//   useEffect(() => {
//     if (seconds > 0) {
//       localStorage.setItem("countdownTime", seconds);
//       localStorage.setItem("countdownTimestamp", Date.now());

//       const timer = setInterval(() => {
//         setSeconds((prevSeconds) => {
//           const newSeconds = prevSeconds - 1;
//           if (newSeconds <= 0) {
//             clearInterval(timer);
//             localStorage.removeItem("countdownTime");
//             localStorage.removeItem("countdownTimestamp");
//           }
//           return newSeconds;
//         });
//       }, 1000);

//       return () => clearInterval(timer);
//     } else {
//       localStorage.removeItem("countdownTime");
//       localStorage.removeItem("countdownTimestamp");
//     }
//   }, [seconds]);

//   return (
//     <div>
//       <h1 className="text-yellow-600">Disponiblité : {seconds} s</h1>
//     </div>
//   );
// };

// export default CountdownTimer;
